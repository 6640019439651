const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

let baseUrl = `${API_BASE_URL}api/v1`;

if (window.location.host === "dorascribe-dev.app") {
  baseUrl = `${API_BASE_URL}api/v1`;
} else if (window.location.host === "dorascribe-staging.app") {
  baseUrl = `${API_BASE_URL}api/v1`;
} else if (window.location.host === "dorascribe-prod.app/") {
  baseUrl = `${API_BASE_URL}api/v1`;
}

module.exports = { baseUrl };
