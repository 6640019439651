import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeadBar from './components/headBar';
// import Home from './components/home';
import Sidebar from './components/sidebar';
import { ActivePageContext, SidebarContext } from './contexts/ActivePageContext';
import Transcribing from './components/transcribing';
// import TranscribedUi from './components/transcribedUI';

const TranscriptionPage = () => {
	const Navigate = useNavigate();

	useEffect(() => {
		const storedItem = localStorage.getItem('dora_token');

		// Check if the token exists
		if (storedItem) {
			return;
		} else {
			// Navigate to the sign-in page if the token doesn't exist
			Navigate('/login');
		}
	}, [Navigate]);

	const { activePage } = useContext(ActivePageContext);
	const { setSidebarVisible } = useContext(SidebarContext);

	useEffect(() => {
		setSidebarVisible(false);
	}, [activePage, setSidebarVisible]);

	return (
		<>
			<div className=''>
				<Sidebar />
				<HeadBar />

				<Transcribing />
			</div>
		</>
	);
};

export default TranscriptionPage;
