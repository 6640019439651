import Faq from "../../components/faq";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import prieHero from "./assets/pricehero.webp";
import pattern from "./assets/Background pattern decorative.png";

const Tutorials = () => {
  return (
    <>
      <Navbar />
      <div className=" px-4 md:px-8 w-full mt-28">
        <div
          style={{
            backgroundImage: `url(${prieHero})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="  h-[329px] w-full flex p-6 flex-col items-center justify-center relative rounded-[20px]"
        >
          <img src={pattern} className=" absolute left-0 -top-[200px]" alt="" />
          <p className=" font-BricolageGrotesque capitalize font-semibold text-4xl md:text-[75px] md:leading-[97px] text-center text-white">
            Dorascribe Tutorials
          </p>
          <p className=" mt-3 font-Inter text-[#f5f5f58b] text-base md:text-xl font-normal text-center lg:w-[800px]">
            Master AI-powered documentation with Dorascribe. Explore
            step-by-step tutorials designed to enhance your workflow.
          </p>
        </div>

        <div className="px-4 md:px-14 w-full py-8 md:py-16 flex justify-center items-center flex-col">
          <p className=" text-center font-BricolageGrotesque font-semibold text-2xl md:text-[40px] text-black">
            How-To Guides
          </p>
          <p className=" text-center font-Inter text-base md:text-lg font-normal mt-3 text-[#282D2D] lg:w-[800px]">
            Improve Efficiency with Our How-To's.
          </p>

          <div className=" mt-16 grid  grid-cols-1 md:grid-cols-3 gap-8 w-full">
            <div className=" w-full h-[300px] bg-[#f1f1f1] rounded-[20px]">
              <iframe
                className=" w-full h-full rounded-[20px]"
                src="https://www.youtube.com/embed/jRnNgcmDfHY"
              ></iframe>
            </div>

            <div className=" w-full h-[300px] bg-[#f1f1f1] rounded-[20px]">
              <iframe
                className=" w-full h-full rounded-[20px]"
                src="https://www.youtube.com/embed/DoDCFIsMTNg"
              ></iframe>
            </div>

            <div className=" w-full h-[300px] bg-[#f1f1f1] rounded-[20px]">
              <iframe
                className=" w-full h-full rounded-[20px]"
                src="https://www.youtube.com/embed/rx6SbZo5Zzk"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Faq />
      <Footer />
    </>
  );
};

export default Tutorials;
