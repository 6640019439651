import { createContext, useState } from "react";

export const ActivePageContext = createContext();
export const SidebarContext = createContext();
export const PlanPriceIdContext = createContext();

const ActivePageProvider = (props) => {
  const [activePage, setActivePage] = useState("Home");
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [fetchData, setFetchData] = useState("not set");
  const [planPriceId, setPlanPriceId] = useState(null);

  return (
    <>
      <ActivePageContext.Provider
        value={{ activePage, setActivePage, fetchData, setFetchData }}
      >
        <PlanPriceIdContext.Provider value={{ planPriceId, setPlanPriceId }}>
          <SidebarContext.Provider
            value={{ sidebarVisible, setSidebarVisible }}
          >
            {props.children}
          </SidebarContext.Provider>
        </PlanPriceIdContext.Provider>
      </ActivePageContext.Provider>
    </>
  );
};

export default ActivePageProvider;
