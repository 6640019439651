import { format } from 'date-fns';

export const formatCreatedAt = (dateString) => {
	const date = new Date(dateString);

	const year = date.getFullYear();
	const month = date.getMonth() + 1; // Months are zero-based
	const day = date.getDate();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	if (isNaN(year) || isNaN(month) || isNaN(day) || isNaN(hours) || isNaN(minutes)) {
		return 'Loading...';
	}

	return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const formatDate = (dateString) => {
	const date = new Date(dateString);

	if (isNaN(date.getTime())) {
		return 'Loading...';
	}

	const day = format(date, 'do'); // 'do' for day with ordinal suffix
	const monthYear = format(date, 'MMMM yyyy');
	const time = format(date, 'h:mm a');

	return `${day} ${monthYear}, ${time}`;
};
