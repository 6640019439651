import { useState } from 'react';
import { handleUserUpdateNote } from '../../../controllers/NoteController';
import close from './assets/close cross.svg';

const GenerateLetter = ({
	noteId,
	// rawTranscript,
	toggleRfLetter,
	setIsLoading,
	setIsGenerated,
	onRefLetterGenerated,
}) => {
	const [instruction, setInstruction] = useState('');

	const handleGenerateRefLetter = async (noteId) => {
		setIsLoading(true);

		let objData = {
			action: 'referral_note',
			content: `${instruction.trim()}`,
		};

		const response = await handleUserUpdateNote(noteId, objData);

		if (response.data) {
			setIsLoading(false);
			onRefLetterGenerated({
				res: response.data.data,
			}); // Emit the response to the parent
		}
	};

	return (
		<div className='w-full md:w-[120%] h-full bg-[#1212128d] z-[99999] fixed top-0 md:-left-[20%] px-6 flex justify-center items-center'>
			<div className='w-full flex justify-center items-center'>
				<div className='md:ml-[20%] bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] h-[320px] p-6 rounded-[15px] w-full md:w-[400px]'>
					<div className='w-full h-full overflow-y-auto bg-white'>
						<span className='flex items-center justify-between'>
							<p className='font-BricolageGrotesque font-semibold text-xl text-[#272D37]'>Referral Letter</p>
							<img src={close} className='cursor-pointer' onClick={toggleRfLetter} alt='' />
						</span>
						<div className='mt-6'>
							<label className='flex w-full flex-col text-[#272D37] font-semibold font-BricolageGrotesque text-sm mt-6'>
								Instruction
								<textarea
									className='mt-[6px] border border-[#DAE0E6] font-Inter text-sm font-normal rounded-[5px] p-2.5'
									name=''
									placeholder='Enter Instruction'
									id=''
									value={instruction}
									onChange={(e) => setInstruction(e.target.value)}
								></textarea>
							</label>
							<p className='mt-2 font-Inter font-normal text-[#5F6D7E] text-sm'>
								This instruction will be used as an output to your generated referral letter
							</p>
							<div className='w-full flex justify-between items-center'>
								<button
									onClick={toggleRfLetter}
									className='mt-6 w-[48%] flex items-center border border-[#DAE0E6] shadow-sm shadow-[#1018280A] rounded-[30px] text-[#272D37] font-BricolageGrotesque font-semibold text-base justify-center text-center py-3'
								>
									Cancel
								</button>
								<button
									onClick={() => {
										setIsGenerated(true);
										toggleRfLetter();
										handleGenerateRefLetter(noteId);
									}}
									className='mt-6 w-[48%] flex items-center bg-[#00AAAA] rounded-[30px] text-white font-BricolageGrotesque font-semibold text-base justify-center text-center py-3'
								>
									Generate
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GenerateLetter;

