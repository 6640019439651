const DropdownMenu = ({ options }) => (
	<div className='absolute top-7 right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10'>
		{options.map((option, index) => (
			<button
				key={index}
				onClick={option.onClick}
				className={`block px-4 py-2 text-sm ${option.textColor || 'text-gray-700'} ${
					option.fontWeight || 'font-normal'
				} hover:bg-gray-100 w-full text-left`}
			>
				{option.label}
			</button>
		))}
	</div>
);

export default DropdownMenu;
