import { useEffect, useState } from "react";
import close from "./assets/close cross.svg";
import upload from "./assets/upload.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GetHelp = ({ closeHelpModal, profileDetails }) => {
  // console.log(profileDetails);

  const [email, setEmail] = useState(profileDetails.email || "");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [doraToken, setDoraToken] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const storedItem = localStorage.getItem("dora_token");
    if (storedItem) {
      setDoraToken(storedItem);
    }
  }, []);

  return (
    <>
      <div className=" w-full md:w-[120%] h-full bg-[#1212128d] z-[999] fixed top-0 md:-left-[20%] px-6 flex justify-center items-center">
        <div className="w-full flex justify-center items-center">
          <div className="md:ml-[20%] bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] h-[280px] p-6 rounded-[15px] w-full md:w-[400px]">
            <div className="w-full h-full overflow-y-auto bg-white">
              <span className=" flex items-center justify-between">
                <p className=" text-[#272D37] font-semibold font-BricolageGrotesque text-xl">
                  Get Help
                </p>
                <img
                  src={close}
                  className="cursor-pointer"
                  onClick={closeHelpModal}
                  alt=""
                />
              </span>
              <p className=" font-Inter text-[#5F6D7E] font-normal text-base mt-1">
                Get in touch with us.
              </p>

              <p className=" mt-4 w-full font-Inter text-base font-normal  text-left">
                If you encounter any technical issues or need assistance, please
                contact us via email at{" "}
                <a
                  href="mailto:help@dorascribe.com"
                  className=" text-[#00aaaa] font-semibold"
                >
                  help@dorascribe.com
                </a>
                . We are committed to promptly addressing your concerns and
                providing the necessary guidance.
              </p>

              {/* inputs  */}
              {/* <div className=" w-full">
                <label
                  htmlFor="email"
                  className="flex flex-col w-full mt-4 font-BricolageGrotesque text-[#272D37] text-sm font-semibold"
                >
                  Email
                  <input
                    id="email"
                    type="text"
                    value={profileDetails.email}
                    readOnly
                    className="w-full py-2.5 px-6 font-Inter text-[#919BA7] text-sm font-normal mt-[6px] border border-[#DAE0E6] rounded-[20px]"
                    placeholder="Enter Email"
                  />
                </label>

                <label
                  htmlFor="reason"
                  className="flex flex-col w-full font-BricolageGrotesque text-[#272D37] text-sm font-semibold mt-6"
                >
                  Reason for contact
                  <input
                    id="reason"
                    type="text"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="w-full py-2.5 px-6 font-Inter capitalize text-[#919BA7] text-sm font-normal mt-[6px] border border-[#DAE0E6] rounded-[20px]"
                    placeholder="Enter Reason for contact"
                  />
                </label>
                <label
                  htmlFor="message"
                  className="flex flex-col w-full font-BricolageGrotesque text-[#272D37] text-sm font-semibold mt-6"
                >
                  Message
                  <textarea
                    id="message"
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="w-full py-2.5 px-6 font-Inter capitalize text-[#919BA7] text-sm font-normal mt-[6px] border border-[#DAE0E6] rounded-[20px]"
                    placeholder="Enter your Message"
                  ></textarea>
                </label>

                <div className="justify-between w-full mt-6 flex items-center flex-row">
                  <button
                    // onClick={handleUpdate}
                    className="py-3 rounded-[30px] shadow-sm shadow-[#1018280A] text-center font-semibold font-BricolageGrotesque text-base text-white w-full bg-[#00AAAA]"
                  >
                    Submit
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetHelp;
