import axios from 'axios';
import { toast } from 'react-toastify';
import { baseUrl } from '../config';
import 'react-toastify/dist/ReactToastify.css';

const api = async (method, uri, body) => {
	const url = baseUrl + uri;

	const token = localStorage.getItem('dora_token');
	if (token) {
		axios.defaults.headers = {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		};
	}

	return new Promise((resolve, reject) => {
		axios[method](url, body)
			.then((res) => {
				if (res.data && res.data.access_token) {
					localStorage.setItem('dora_token', res.data.access_token);
				} else toast.success(res?.data ? res.data.data.message : 'Request successful!');

				resolve(res);
			})
			.catch((err) => {
				if (err?.response?.status === 403) {
					localStorage.removeItem('token');
					window.location = '/login';
				} else if (err?.response?.status === 409) {
					toast.error(err?.response?.data ? err.response.data.data.error : 'Something went wrong');
					reject(err);
				} else if (err?.response?.status === 422) {
					console.log('rr?.response?.data: ', err?.response?.data);
					toast.error(err?.response?.data ? err.response?.data?.message : 'Something went wrong');
					reject(err);
				} else {
					console.log('API Error --------> ', err?.response?.status);
					toast.error(err.response?.data?.message ? err.response?.data?.message : err?.message);
					reject(err);
				}
			});
	});
};

export default api;
