const WaveAnimation = () => {
	return (
		<div className='flex items-center justify-center space-x-1 h-16'>
			<div
				className='bg-[#0DABAB] wave-bar'
				style={{ animationDelay: '0.1s', width: '4px', height: '20%', borderRadius: '4px' }}
			></div>
			<div
				className='bg-[#0DABAB] wave-bar'
				style={{ animationDelay: '0.2s', width: '4px', height: '20%', borderRadius: '4px' }}
			></div>
			<div
				className='bg-[#0DABAB] wave-bar'
				style={{ animationDelay: '0.3s', width: '4px', height: '20%', borderRadius: '4px' }}
			></div>
			<div
				className='bg-[#0DABAB] wave-bar'
				style={{ animationDelay: '0.4s', width: '4px', height: '20%', borderRadius: '4px' }}
			></div>
			<div
				className='bg-[#0DABAB] wave-bar'
				style={{ animationDelay: '0.5s', width: '4px', height: '20%', borderRadius: '4px' }}
			></div>
			<div
				className='bg-[#0DABAB] wave-bar'
				style={{ animationDelay: '0.6s', width: '4px', height: '20%', borderRadius: '4px' }}
			></div>
			<div
				className='bg-[#0DABAB] wave-bar'
				style={{ animationDelay: '0.7s', width: '4px', height: '20%', borderRadius: '4px' }}
			></div>
			<div
				className='bg-[#0DABAB] wave-bar'
				style={{ animationDelay: '0.8s', width: '4px', height: '20%', borderRadius: '4px' }}
			></div>
		</div>
	);
};

export default WaveAnimation;
