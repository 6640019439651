import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleUserLoginWithOTP } from "../../controllers/UserController";

const LoginOTP = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const navigate = useNavigate();

  const onSuccess = (response) => {
    if (response) navigate("/authenticatelogin");
  };

  const handleLoginClick = () => {
    if (!email) {
      setError("Email is required");
      return;
    }
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    setError("");

    const userData = { email };
    handleUserLoginWithOTP(userData, onSuccess);
  };

  return (
    <>
      <input
        type="text"
        className="mt-3 border border-[#EAEBF0] w-full md:w-[500px] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
        placeholder="Enter your Email address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {error && (
        <p className="mt-2 text-red-600 font-Inter font-normal text-sm">
          {error}
        </p>
      )}
      <button
        className="w-full md:w-[500px] rounded-[50px] bg-[#0dabab] text-base font-semibold text-white font-BricolageGrotesque py-4 mt-6"
        onClick={handleLoginClick}
      >
        Proceed
      </button>
    </>
  );
};

export default LoginOTP;
