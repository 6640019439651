import { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActivePageContext } from '../contexts/ActivePageContext';

// Controllers
import {
	getTemplates,
	handleGetNoteById,
	handleUserUpdateNote,
	handleGetNoteResponseUpdate,
	handleNoteResponseUpdate,
	handleNoteChange,
} from '../../../controllers/NoteController';

// Components
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ReactMarkdown from 'react-markdown';

// Utils
import DOMPurify from 'dompurify';
import TurndownService from 'turndown';
import toast, { Toaster } from 'react-hot-toast';
import useLocalStorageListener from '../hooks/useLocalStorageListener';

// Plugins
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { FaStar } from 'react-icons/fa';

// Styles
import './assets/MarkdownComponent.css';

// Assets
import edit from './assets/NotePencil.svg';
import down from './assets/CaretDown.svg';
import sparkle from './assets/Sparkle.svg';
import copy from './assets/CopyWhite.svg';
import mic from './assets/mic.svg';
import thumbsUp from './assets/thumbs-up.svg';
import thumbsDown from './assets/thumbs-down.svg';
import paperPlane from './assets/PaperPlaneRight.svg';
import leftShape from './assets/leftshape.svg';
import check from './assets/check.svg';

// Local Components
import CreateTemp from './createTemp';
import EditTemp from './editTemp';
import EditNote from './editNote';
import ChangeNoteModal from './changeNoteModal';
import GenerateLetter from './generateLetter';
import AllNotesMobile from './allNotesMobile';

import dataPrivacy from './assets/data_privacy.pdf';

const TranscribedUi = () => {
	const { setActivePage } = useContext(ActivePageContext);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');
	const dropdownRef = useRef(null);
	const [createTemplate, setCreateTemplate] = useState(false);
	const [editTemplate, setEditTemplate] = useState(false);
	const [rfLetter, setRfLetter] = useState(false);
	const [isExpanded, setIsExpanded] = useState(true);
	// const [showAllNotes, setShowAllNotes] = useState(false);
	const [isGenerated, setIsGenerated] = useState(false);
	const [isReferralLetterExpanded, setIsReferralLetterExpanded] = useState(true);
	// const [isTranscriptExpanded, setIsTranscriptExpanded] = useState(false);
	const [isRawTranscriptExpanded, setIsRawTranscriptExpanded] = useState(false);

	const [patientName, setPatientName] = useState('');
	const [patientPronoun, setPatientPronoun] = useState('');
	const [noteTypes, setNoteTypes] = useState([]);
	const [generatedNoteContent, setGeneratedNoteContent] = useState([]);
	const [generatedReferralNote, setGeneratedReferralNote] = useState([]);
	// const [refTranscript, setRefinedTranscript] = useState('');
	const [rawTranscript, setRawTranscript] = useState('');
	const [noteContent, setNoteContent] = useState('');
	const [referralNote, setReferralNote] = useState('');
	const [noteTitle, setNoteTitle] = useState('');
	const [noteType, setNoteType] = useState('');
	const [editNote, setEditNote] = useState(false);
	const [changeNoteNotification, setChangeNoteNotification] = useState(false);
	const [noteId, setNoteId] = useLocalStorageListener('note_id', null);
	// const [isTranscriptEditing, setIsTranscriptEditing] = useState(false);
	const [isRawTranscriptEditing, setIsRawTranscriptEditing] = useState(false);
	const [isNoteEditing, setIsNoteEditing] = useState(false);
	const [isReferralEditing, setIsReferralEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const [selectedSoapNoteVersion, setSelectedSoapNoteVersion] = useState('');
	const [selectedReferralVersion, setSelectedReferralVersion] = useState('');
	// const [selectedRefTranscriptVersion, setSelectedRefTranscriptVersion] = useState('');
	// const [selectedRawTranscriptVersion, setSelectedRawTranscriptVersion] =
	//   useState("");
	const [soapNoteDropdownVisible, setSoapNoteDropdownVisible] = useState(false);
	const [referralDropdownVisible, setReferralDropdownVisible] = useState(false);

	const [hoveredStar, setHoveredStar] = useState(null);
	const [selectedRating, setSelectedRating] = useState(0); // Store the selected rating
	const [showMenu, setShowMenu] = useState(false);

	const tooltips = [
		"Don't like the response",
		'Hallucination',
		'I can manage the format',
		'It is good and I can use it',
		'I love it',
	];

	const handleStarClick = (rating) => {
		setSelectedRating(rating); // Set selected rating
		console.log(`Selected Rating: ${rating}`);

		// // Send the rating to the backend (using axios for example)
		// axios
		// 	.post('/api/rating', { rating }) // Replace with your actual API endpoint
		// 	.then((response) => {
		// 		console.log('Rating submitted successfully:', response.data);
		// 	})
		// 	.catch((error) => {
		// 		console.error('Error submitting rating:', error);
		// 	});
	};

	const noteRef = useRef(null);
	const referralRef = useRef(null);
	// const transcriptRef = useRef(null);
	const transcriptRawRef = useRef(null);
	const turndownService = new TurndownService();
	const Navigate = useNavigate();

	const fetchNoteData = useCallback((id) => {
		setIsLoading(true);

		handleGetNoteById(id)
			.then((response) => {
				const data = response.data.data;

				if (data) setIsLoading(false);

				setSelectedSoapNoteVersion(data?.selected_note_contents);
				setSelectedReferralVersion(data?.selected_referral_note);
				// setSelectedRefTranscriptVersion(data?.refined_transcription);
				// setSelectedRawTranscriptVersion(data?.raw_transcription);

				const patientName = data?.patient_name;
				const patientPronoun = data?.patient_pronoun;
				const generatedNoteContents = data?.generate_note_contents;
				const generatedReferralNotes = data?.generated_referral_notes;
				// const refinedTranscriptionContent = removeBackticks(data?.refined_transcription?.content);
				const rawTranscriptionContent = removeBackticks(data?.raw_transcription);
				const selectedNoteContent = removeBackticks(
					data?.selected_note_contents?.content
						? data?.selected_note_contents?.content
						: generatedNoteContents[0]?.content
				);
				const selectedReferralContent = removeBackticks(data?.selected_referral_note?.content);
				const noteTitle = data?.template?.name;
				const noteType = data?.template?.type;

				setPatientName(patientName);
				setPatientPronoun(patientPronoun);
				setGeneratedNoteContent(generatedNoteContents);
				setGeneratedReferralNote(generatedReferralNotes);
				// setRefinedTranscript(DOMPurify.sanitize(refinedTranscriptionContent));
				setRawTranscript(DOMPurify.sanitize(rawTranscriptionContent));
				setNoteContent(DOMPurify.sanitize(selectedNoteContent));
				setReferralNote(DOMPurify.sanitize(selectedReferralContent));
				setNoteTitle(noteTitle);
				setNoteType(noteType);
			})
			.catch((error) => {
				console.error('Error fetching note data:', error);
			});
	}, []);

	const removeSubjective = (note, index) => {
		const keyword = 'SUBJECTIVE:';
		const lowerCaseNote = note.toLowerCase();
		const lowerCaseKeyword = keyword.toLowerCase();

		return `V${index + 1} - ${lowerCaseNote.startsWith(lowerCaseKeyword) ? note.slice(keyword.length).trim() : note}`;
	};

	const toggleEditNote = () => {
		setEditNote(!editNote);
	};

	const onNoteEdited = (params) => {
		setPatientName(params.patient_name);
		setPatientPronoun(params.patient_pronoun);
		fetchNoteData(noteId);
	};

	const toggleRfLetter = () => {
		setRfLetter(!rfLetter);
	};

	const startEditingNote = () => {
		setIsNoteEditing(true);
	};

	const startEditingReferral = () => {
		setIsReferralEditing(true);
	};

	// const startEditingTranscript = () => {
	// 	setIsTranscriptEditing(true);
	// };

	const startEditingRawTranscript = () => {
		setIsRawTranscriptEditing(true);
	};

	const handleBlur = async (ref, setEditing, setContent, selectedVersion) => {
		setEditing(false);
		setIsLoading(true);

		if (ref.current) {
			const updatedHtml = ref.current.innerHTML;
			const updatedContent = turndownService.turndown(updatedHtml);

			let objData = {
				content: updatedContent,
				like: false,
			};

			const response = await handleNoteResponseUpdate(selectedVersion?.id, objData);
			if (response) {
				setContent(response.data.data.content);
				fetchNoteData(noteId);
			}
		}
	};

	const handleNoteBlur = () => handleBlur(noteRef, setIsNoteEditing, setNoteContent, selectedSoapNoteVersion);

	const handleReferralBlur = () =>
		handleBlur(referralRef, setIsReferralEditing, setReferralNote, selectedReferralVersion);

	// const handleTranscriptBlur = () =>
	// 	handleBlur(transcriptRef, setIsTranscriptEditing, setRefinedTranscript, selectedRefTranscriptVersion);

	// const handleRawTranscriptBlur = () =>
	// 	handleBlur(transcriptRawRef, setIsRawTranscriptEditing, setRawTranscript, selectedRawTranscriptVersion);

	const handleNoteResponse = async (dataObj) => {
		setIsLoading(true);
		let response;

		if (dataObj.template_id) {
			response = await handleNoteChange(noteId, dataObj);
		} else {
			response = await handleUserUpdateNote(noteId, dataObj);
		}
		const noteData = response.data.data;

		if (noteData) setIsLoading(false);

		let genNoteCont = noteData.generate_note_contents;
		genNoteCont.sort((a, b) => b.id - a.id);

		setGeneratedNoteContent(genNoteCont);

		const latestNote = genNoteCont[0];
		setSelectedSoapNoteVersion(latestNote);

		if (latestNote) {
			const sanitizedContent = DOMPurify.sanitize(latestNote.content);
			setNoteContent(sanitizedContent);
		}

		if (noteData.template) {
			const noteTitle = noteData?.template?.name;
			setNoteTitle(noteTitle);
		}

		// const refinedTranscriptionOnReGenerate = removeBackticks(noteData?.refined_transcription?.content);
		// setRefinedTranscript(DOMPurify.sanitize(refinedTranscriptionOnReGenerate));
	};

	const handleNoteRegenerate = async () => {
		const objData = {
			action: 'generate_note_content',
		};

		await handleNoteResponse(objData);
	};

	// const handleReGenerateRefinedTranscript = async () => {
	// 	const objData = {
	// 		action: 'refine_transcription',
	// 	};

	// 	await handleNoteResponse(objData);
	// };

	const handleProceedNoteChange = async () => {
		setChangeNoteNotification(false);

		const dataObj = {
			action: 'generate_note_content',
			// content: refTranscript,
			template_id: selectedOption.id,
		};

		await handleNoteResponse(dataObj);
	};

	// const handleRefLetterRegenerate = async () => {

	// 	setIsLoading(true);
	// 	let objData = {
	// 		action: 'referral_note',
	// 		content: '',
	// 		// content: rawTranscript ? rawTranscript.trim() : 'another format',
	// 	};

	// 	const response = await handleUserUpdateNote(noteId, objData);
	// 	let genRefNote = response.data.data.generated_referral_notes;

	// 	genRefNote.sort((a, b) => b.id - a.id);

	// 	setGeneratedReferralNote(genRefNote);

	// 	const regeneratedRefNote = genRefNote[0];

	// 	if (regeneratedRefNote) {
	// 		setIsLoading(false);
	// 		const sanitizedRegeneratedRefNote = DOMPurify.sanitize(regeneratedRefNote.content);
	// 		setReferralNote(sanitizedRegeneratedRefNote);
	// 	}
	// };

	const toggleAccordion = () => {
		setIsExpanded(!isExpanded);
	};

	const toggleReferralLetterAccordion = () => {
		setIsReferralLetterExpanded(!isReferralLetterExpanded);
	};

	// const toggleTranscriptAccordion = () => {
	// 	setIsTranscriptExpanded(!isTranscriptExpanded);
	// };

	const toggleRawTranscriptAccordion = () => {
		setIsRawTranscriptExpanded(!isRawTranscriptExpanded);
	};

	const onCloseCreateTemplate = () => {
		setCreateTemplate(false);
	};
	const onCloseEditTemplate = () => {
		setEditTemplate(false);
	};

	const toggleDropdown = () => {
		setDropdownVisible((prevState) => !prevState);
	};

	const handleOptionClick = (option) => {
		setChangeNoteNotification(true);
		setSelectedOption(option);
	};

	const handleCloseNote = () => {
		setChangeNoteNotification(false);
	};

	const handleClickOutsideType = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownVisible(false);
		}
	};

	const removeBackticks = (markdown) => {
		// if (markdown) return markdown.replace(/```markdown|```$/g, '');
		if (markdown) return markdown.replace(/^```markdown|```$/g, '');
	};

	const handleShowAllNotes = () => {
		// setShowAllNotes(true);
		setActivePage('Home');
	};

	const handleEditNote = () => {
		localStorage.setItem('note_id', JSON.stringify(noteId));
		Navigate('/transcription');
	};

	const handleDownload = () => {
		const link = document.createElement('a');
		link.href = dataPrivacy;
		link.download = 'data-privacy.pdf';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	useEffect(() => {
		const getNoteTemplates = async () => {
			const data = await getTemplates();
			setNoteTypes(data.data.data);
		};

		getNoteTemplates();
	}, []);

	useEffect(() => {
		const storedNoteId = localStorage.getItem('note_id');
		setNoteId(JSON.parse(storedNoteId));
	}, [setNoteId]); // Empty dependency array to run only once on mount

	useEffect(() => {
		if (noteId !== null) {
			fetchNoteData(noteId);
		}
	}, [fetchNoteData, noteId]);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideType);
		return () => {
			document.removeEventListener('mousedown', handleClickOutsideType);
		};
	}, []);

	const handleSoapNoteOptionClick = (option) => {
		setSelectedSoapNoteVersion(option);

		handleGetNoteResponseUpdate(option.id).then((res) => {
			const data = DOMPurify.sanitize(res.data.data.content);
			setNoteContent(data);
		});
	};

	const handleRefLetterGenerated = (data) => {
		// handing
		if (data.loader) setIsLoading(false);
		let genRefNote = data.res.generated_referral_notes;

		genRefNote.sort((a, b) => b.id - a.id);

		setGeneratedReferralNote(genRefNote);

		const regeneratedRefNote = data.res.selected_referral_note;
		setSelectedReferralVersion(regeneratedRefNote);

		if (regeneratedRefNote.content) {
			setIsLoading(false);
			const sanitizedRegeneratedRefNote = DOMPurify.sanitize(regeneratedRefNote.content);
			setReferralNote(sanitizedRegeneratedRefNote);
		}
	};

	// Function to handle selecting an option for Referral Letter dropdown
	const handleReferralOptionClick = (option) => {
		setSelectedReferralVersion(option);

		handleGetNoteResponseUpdate(option.id).then((res) => {
			const data = DOMPurify.sanitize(res.data.data.content);
			setReferralNote(data);
		});
	};

	// Function to toggle visibility of SOAP Note dropdown
	const toggleSoapNoteDropdown = () => {
		setSoapNoteDropdownVisible((prevState) => !prevState);
	};

	// Function to toggle visibility of Referral Letter dropdown
	const toggleReferralDropdown = () => {
		setReferralDropdownVisible((prevState) => !prevState);
	};

	// Event listener to close dropdowns when clicking outside
	const handleClickOutside = (event) => {
		const soapNoteDropdown = document.getElementById('soapNoteDropdown');
		const referralDropdown = document.getElementById('referralDropdown');

		if (soapNoteDropdown && !soapNoteDropdown.contains(event.target)) {
			setSoapNoteDropdownVisible(false);
		}

		if (referralDropdown && !referralDropdown.contains(event.target)) {
			setReferralDropdownVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text).then(
			() => {
				toast.success('Copied to clipboard!', {
					duration: 4000,
					position: 'top-center',
				});
			},
			(err) => {
				if (err)
					toast.error('Could not copy text!', {
						duration: 4000,
						position: 'top-center',
					});
			}
		);
	};

	return (
		<>
			<Toaster />
			{createTemplate && <CreateTemp onCloseCreateTemplate={onCloseCreateTemplate} />}
			{editNote && <EditNote noteId={noteId} toggleEditNote={toggleEditNote} onNoteEdited={onNoteEdited} />}
			{changeNoteNotification && (
				<ChangeNoteModal
					noteTitle={noteTitle}
					selectedOption={selectedOption}
					onCloseNote={handleCloseNote}
					onProceedNoteChange={handleProceedNoteChange}
				/>
			)}
			{rfLetter && (
				<GenerateLetter
					noteId={noteId}
					// rawTranscript={rawTranscript}
					onRefLetterGenerated={handleRefLetterGenerated}
					toggleRfLetter={toggleRfLetter}
					setIsGenerated={setIsGenerated}
					setIsLoading={setIsLoading}
				/>
			)}
			{editTemplate && <EditTemp onCloseEditTemplate={onCloseEditTemplate} />}

			<div className='absolute lg:left-[25%] top-[70px] p-4 md:px-12 md:py-8 w-full lg:w-[75%]'>
				{/* mobile controller  */}
				<button onClick={handleShowAllNotes} className='flex md:hidden items-center space-x-3 mb-4'>
					<img src={leftShape} alt='' />
					<p className='text-lg'>All Notes</p>
				</button>

				{/* {showAllNotes && <AllNotesMobile />} */}

				<div className=' flex flex-col md:flex-row justify-between md:items-center mt-3 md:mt-0 gap-2 md:gap-1'>
					{isLoading ? (
						<SkeletonTheme color='#202020' highlightColor='#aaa'>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Skeleton height={20} width={140} count={1} />
							</div>
						</SkeletonTheme>
					) : (
						<span className=' flex items-center'>
							<p className=' font-BricolageGrotesque text-lg md:text-2xl font-semibold text-[#272D37]'>{patientName}</p>
							<img onClick={toggleEditNote} src={edit} className='cursor-pointer ml-1' alt='' />
							<span className=' py-[2px] px-2 rounded-[5px] bg-[#ECFDFD] text-[#00AAAA] font-Inter text-base font-medium ml-3'>
								{patientPronoun}
							</span>
						</span>
					)}

					<div className='flex justify-between md:justify-end gap-2'>
						<button
							onClick={handleEditNote}
							className='flex items-center px-2 py-1 md:px-5 md:py-3 font-BricolageGrotesque text-sm md:text-base rounded-[40px] font-semibold shadow hover:shadow-[#007272] transition-all bg-[#00AAAA] text-white'
						>
							<span>Edit Transcription</span>
						</button>
						<button
							onClick={handleDownload}
							className='hidden items-center px-2 py-1 md:px-5 md:py-3 font-BricolageGrotesque text-xs md:text-base rounded-[40px] font-semibold shadow hover:shadow-[#ccc] transition-all'
						>
							<span>Download data privacy</span>
						</button>
					</div>

					<div className='hidden md:space-x-3 items-center w-auto'>
						<button
							onClick={() => {
								setCreateTemplate(true);
							}}
							className=' py-[10px] hidden md:flex px-4 border border-[#DAE0E6] rounded-[5px] items-center space-x-[6px]'
						>
							<img src={sparkle} alt='' />
							<p className='  text-base font-Inter font-medium text-[#272D37]'>Create New Templates</p>
						</button>
					</div>
				</div>
				<button
					onClick={() => {
						setCreateTemplate(true);
					}}
					className='hidden py-[10px] mt-3 md:mt-0 md:hidden px-4 border border-[#DAE0E6] rounded-[5px] items-center space-x-[6px]'
				>
					<img src={sparkle} alt='' />
					<p className='  text-base font-Inter font-medium text-[#272D37]'>Create New Templates</p>
				</button>

				<div className=' mt-8 w-full mb-20'>
					{/* soap notes */}
					<div className='w-full shadow-sm rounded-[5px] border border-[#EAEBF0] shadow-[#1018280A]'>
						<div className='w-full p-5 flex flex-row justify-between items-center cursor-pointer hover:bg-gray-100'>
							{isLoading ? (
								<SkeletonTheme color='#202020' highlightColor='#aaa'>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Skeleton height={20} width={140} count={1} />
									</div>
								</SkeletonTheme>
							) : (
								<p
									onClick={toggleAccordion}
									className='w-[100px] md:w-1/2 text-sm md:text-base font-BricolageGrotesque font-semibold text-[#272D37] py-[10px]'
								>
									{noteType}
								</p>
							)}
							{isLoading ? (
								<SkeletonTheme color='#202020' highlightColor='#aaa'>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Skeleton height={20} width={140} count={1} />
									</div>
								</SkeletonTheme>
							) : (
								<div className='flex'>
									<div
										ref={dropdownRef}
										className=' relative py-[10px] px-4 border border-[#DAE0E6] rounded-[5px] md:w-[250px] '
									>
										<button
											onClick={toggleDropdown}
											className='w-full h-full flex items-center space-x-3 md:space-x-0 justify-between'
										>
											<p className='text-sm md:text-base font-Inter font-medium text-[#272D37]'>
												{selectedOption ? selectedOption.name : noteTitle}
											</p>
											<img
												src={down}
												className={`cursor-pointer transition-transform ${dropdownVisible ? 'rotate-180' : 'rotate-0'}`}
												alt=''
											/>
										</button>
										{/* Type dropdown */}
										{dropdownVisible && (
											<div
												id='dropdown-options'
												className=' w-[200px] md:w-full z-20 flex flex-col absolute bg-white right-0 top-12 border border-[#DAE0E6] rounded-[5px]'
											>
												{noteTypes.map((option, index) => {
													if (option.type === 'span') {
														return (
															<span
																key={option?.id}
																className='block py-[6px] px-4 font-Inter font-normal text-base text-[#272D37]'
															>
																{option.name}
															</span>
														);
													}
													return (
														<button
															key={option?.id}
															onClick={() => handleOptionClick(option)}
															className={`w-full transition-all flex items-center justify-between text-left py-[10px] px-4 text-[#272D37] font-Inter font-medium text-base ${
																selectedOption.id === option?.id ? 'bg-[#688E8E26]' : 'hover:bg-[#688E8E26]'
															}`}
														>
															{option.name}
															{selectedOption.id === option?.id && <img src={check} alt='Checked' />}
															{option.icon && (
																<img
																	onClick={() => {
																		setEditTemplate(true);
																	}}
																	src={option.icon}
																	alt='Edit'
																/>
															)}
														</button>
													);
												})}
											</div>
										)}
									</div>

									<img
										onClick={toggleAccordion}
										src={down}
										alt=''
										className={`cursor-pointer transition-transform duration-300 ml-2 ${
											isExpanded ? 'rotate-180' : 'rotate-0'
										}`}
									/>
								</div>
							)}
						</div>
						{/* collapse */}
						<div className={`transition-all duration-300 ${isExpanded ? 'block' : 'hidden'}`}>
							<div className='border-[#EAEBF0] border-y py-4 px-5'>
								<div className='text-[#5F6D7E] font-Inter font-normal text-base'>
									{isLoading ? (
										<SkeletonTheme color='#202020' highlightColor='#aaa'>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<Skeleton height={30} count={1} />
											</div>
										</SkeletonTheme>
									) : (
										<div
											className='markdown-container px-2'
											onClick={startEditingNote}
											onBlur={handleNoteBlur}
											contentEditable={isNoteEditing}
											suppressContentEditableWarning={true}
											ref={noteRef}
										>
											<ReactMarkdown
												children={noteContent ? noteContent : 'Note not found'}
												remarkPlugins={[remarkGfm]}
												rehypePlugins={[rehypeRaw]}
												components={{
													strong: ({ node, ...props }) => <strong className='bold-text' {...props} />,
													p: ({ node, ...props }) => <p className='paragraph-text' {...props} />,
												}}
											/>
										</div>
									)}
								</div>
							</div>

							<div className=' py-4 px-5 flex flex-col md:flex-row items-center justify-between w-full'>
								<div className=' flex items-center w-full md:w-auto justify-between md:justify-start md:space-x-3'>
									{isLoading ? (
										<SkeletonTheme color='#202020' highlightColor='#aaa'>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													gap: '10px',
												}}
											>
												<Skeleton height={20} width={90} count={1} />
												<Skeleton height={20} width={140} count={1} />
											</div>
										</SkeletonTheme>
									) : (
										<>
											<button
												onClick={handleNoteRegenerate}
												className=' px-4 py-2 text-[#272d37] font-Inter text-sm md:text-base font-medium text-center shadow-sm shadow-[#1018280A] bg-[#E1EAEA] rounded-[5px]'
											>
												Regenerate
											</button>

											<div
												id='soapNoteDropdown'
												className=' relative py-2 md:py-[10px] px-4 border border-[#DAE0E6] rounded-[5px] md:w-[250px] '
											>
												<button
													onClick={toggleSoapNoteDropdown}
													className='w-full h-full flex items-center space-x-3 md:space-x-0 justify-between'
												>
													<p className='text-base font-Inter font-medium text-[#272D37]'>Draft Versions</p>
													<img
														src={down}
														className={`cursor-pointer transition-transform ${
															soapNoteDropdownVisible ? 'rotate-180' : 'rotate-0'
														}`}
														alt=''
													/>
												</button>
												{/* SOAP Note dropdown */}
												{soapNoteDropdownVisible && (
													<div className='w-full z-20 flex flex-col absolute bg-white left-0 top-12 border border-[#DAE0E6] rounded-[5px]'>
														{generatedNoteContent.map((option, index) => (
															<button
																key={option?.id}
																onClick={() => handleSoapNoteOptionClick(option)}
																className={`w-full transition-all flex items-center justify-between text-left py-[10px] px-4 text-[#272D37] font-Inter font-medium text-base ${
																	selectedSoapNoteVersion?.id === option?.id ? 'bg-[#688E8E26]' : 'hover:bg-[#688E8E26]'
																}`}
															>
																{removeSubjective(option.title, index)}
															</button>
														))}
													</div>
												)}
											</div>
										</>
									)}
								</div>
								{/* like and dislike  */}
								<div className=' flex flex-row w-full md:w-auto justify-between mt-4 md:mt-0'>
									{isLoading ? (
										<div className='md:hidden'>
											<SkeletonTheme color='#202020' highlightColor='#aaa'>
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													<Skeleton height={20} width={140} count={1} />
												</div>
											</SkeletonTheme>
										</div>
									) : (
										<button
											onClick={() => copyToClipboard(noteContent)}
											className=' py-2 px-4 bg-[#000000] text-[#fff] rounded-[5px] md:hidden flex items-center space-x-[6px]'
										>
											<img src={copy} alt='' />
											<p className='text-sm md:text-base font-Inter font-medium'>Copy</p>
										</button>
									)}
									<div className=' flex items-center'>
										{isLoading ? (
											<SkeletonTheme color='#202020' highlightColor='#aaa'>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														gap: '10px',
													}}
												>
													<Skeleton height={20} width={45} count={1} />
													{/* <Skeleton height={20} width={45} count={1} />
													<Skeleton height={20} width={45} count={1} /> */}
												</div>
											</SkeletonTheme>
										) : (
											<>
												{/* <div className='hidden relative inline-block'> */}
												<div className='hidden relative'>
													<button
														className='flex py-2 px-4 items-center space-x-[6px] relative mr-1'
														onMouseEnter={() => setShowMenu(true)}
														onMouseLeave={() => setShowMenu(false)}
													>
														<img src={thumbsUp} alt='like' />
														<p className='font-Inter text-sm md:text-base font-medium text-[#272D37]'>Like</p>
													</button>

													{showMenu && (
														<div
															className='absolute top-[100%] right-0 md:right-auto p-2 bg-white border rounded-md shadow-lg z-10'
															onMouseEnter={() => setShowMenu(true)}
															onMouseLeave={() => setShowMenu(false)}
														>
															<div className='flex space-x-2'>
																{tooltips.map((tooltip, index) => (
																	<div key={index} className='relative'>
																		<FaStar
																			className={`cursor-pointer text-gray-400 hover:text-yellow-500 ${
																				hoveredStar === index ? 'text-yellow-500' : ''
																			} ${selectedRating > index ? 'text-yellow-500' : ''}`}
																			onMouseEnter={() => setHoveredStar(index)}
																			onMouseLeave={() => setHoveredStar(null)}
																			onClick={() => handleStarClick(index + 1)}
																		/>

																		{hoveredStar === index && (
																			<div className='absolute flex flex-nowrap whitespace-nowrap bottom-full mb-1 left-1/2 transform -translate-x-1/2 p-1 text-xs text-white bg-black rounded'>
																				{tooltip}
																			</div>
																		)}
																	</div>
																))}
															</div>
														</div>
													)}
												</div>
												<button className='hidden py-2 pl-4 md:pr-4 rounded-[5px] bg-[#fff] items-center space-x-[6px]'>
													<img src={thumbsDown} alt='Dislike' />
													<p className=' font-Inter text-base font-medium text-[#272D37]'>Dislike</p>
												</button>
												<button
													onClick={() => copyToClipboard(noteContent)}
													className=' py-2 px-4 rounded-[5px] hidden md:flex items-center bg-[#000000] text-[#fff] space-x-[6px]'
												>
													<img src={copy} alt='' />
													<p className='text-base font-Inter font-medium'>Copy</p>
												</button>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* Referral Letter */}
					<div className='w-full shadow-sm rounded-[5px] border border-[#EAEBF0] shadow-[#1018280A] mt-4'>
						<div
							onClick={toggleReferralLetterAccordion}
							className='w-full p-5 flex flex-row justify-between items-center cursor-pointer hover:bg-gray-100'
						>
							{isLoading ? (
								<SkeletonTheme color='#202020' highlightColor='#aaa'>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Skeleton height={20} width={140} count={1} />
									</div>
								</SkeletonTheme>
							) : (
								<p className='text-sm md:text-base font-BricolageGrotesque font-semibold text-[#272D37]'>
									Referral Letter
								</p>
							)}
							{isGenerated || referralNote !== '' ? (
								<img
									src={down}
									alt=''
									className={`cursor-pointer transition-transform duration-300 ${
										isReferralLetterExpanded ? 'rotate-180' : 'rotate-0'
									}`}
								/>
							) : isLoading ? (
								<SkeletonTheme color='#202020' highlightColor='#aaa'>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Skeleton height={20} width={140} count={1} />
									</div>
								</SkeletonTheme>
							) : (
								<button
									onClick={toggleRfLetter}
									className='bg-[#E1EAEA] py-2 px-4 rounded-[5px] shadow-sm shadow-[#1018280A] text-[#272D37] font-Inter font-medium text-sm md:text-base'
								>
									Generate
								</button>
							)}
						</div>
						{(isGenerated || referralNote !== '') && (
							<div className={`transition-all duration-300 ${isReferralLetterExpanded ? 'block' : 'hidden'}`}>
								<div className=' border-[#EAEBF0] border-y py-4 px-5'>
									<div className='text-[#5F6D7E] font-Inter font-normal text-base'>
										{isLoading ? (
											<SkeletonTheme color='#202020' highlightColor='#aaa'>
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													<Skeleton height={30} count={1} />
												</div>
											</SkeletonTheme>
										) : (
											<div
												className='markdown-container px-2'
												onClick={startEditingReferral}
												onBlur={handleReferralBlur}
												contentEditable={isReferralEditing}
												suppressContentEditableWarning={true}
												ref={referralRef}
											>
												<ReactMarkdown
													children={referralNote ? referralNote : 'Referral note not found'}
													remarkPlugins={[remarkGfm]}
													rehypePlugins={[rehypeRaw]}
													components={{
														strong: ({ node, ...props }) => <strong className='bold-text' {...props} />,
														p: ({ node, ...props }) => <p className='paragraph-text' {...props} />,
													}}
												/>
											</div>
										)}
									</div>
								</div>
								<div className=' py-4 px-5 flex flex-col md:flex-row items-center justify-between w-full'>
									<div className=' flex items-center w-full md:w-auto justify-between md:justify-start md:space-x-3'>
										{isLoading ? (
											<SkeletonTheme color='#202020' highlightColor='#aaa'>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														gap: '10px',
													}}
												>
													<Skeleton height={20} width={90} count={1} />
													<Skeleton height={20} width={140} count={1} />
												</div>
											</SkeletonTheme>
										) : (
											<>
												<button
													onClick={toggleRfLetter}
													className='mr-1 px-4 py-2 text-[#272d37] font-Inter text-base font-medium text-center shadow-sm shadow-[#1018280A] bg-[#E1EAEA] rounded-[5px]'
												>
													Regenerate
												</button>
												<div
													id='referralDropdown'
													className=' relative py-2 md:py-[10px] px-4 border border-[#DAE0E6] rounded-[5px] md:w-[250px] flex items-center justify-between '
												>
													<button
														onClick={toggleReferralDropdown}
														className='w-full h-full flex space-x-3 md:space-x-0 items-center justify-between'
													>
														<p className='text-base font-Inter font-medium text-[#272D37]'>Draft Versions</p>
														<img
															src={down}
															className={`cursor-pointer transition-transform ${
																referralDropdownVisible ? 'rotate-180' : 'rotate-0'
															}`}
															alt=''
														/>
													</button>
													{/* Referral Letter dropdown */}
													{referralDropdownVisible && (
														<div className='w-full z-20 flex flex-col absolute bg-white left-0 top-12 border border-[#DAE0E6] rounded-[5px]'>
															{generatedReferralNote.map((option, index) => (
																<button
																	key={option?.id}
																	onClick={() => handleReferralOptionClick(option)}
																	className={`w-full transition-all flex items-center justify-between text-left py-[10px] px-4 text-[#272D37] font-Inter font-medium text-base ${
																		selectedReferralVersion?.id === option?.id
																			? 'bg-[#688E8E26]'
																			: 'hover:bg-[#688E8E26]'
																	}`}
																>
																	{option.title}
																</button>
															))}
														</div>
													)}
												</div>
											</>
										)}
									</div>
									{/* like and dislike  */}
									<div className=' flex flex-row w-full md:w-auto justify-between mt-4 md:mt-0'>
										{isLoading ? (
											<div className='md:hidden'>
												<SkeletonTheme color='#202020' highlightColor='#aaa'>
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														<Skeleton height={20} width={140} count={1} />
													</div>
												</SkeletonTheme>
											</div>
										) : (
											<button
												onClick={() => copyToClipboard(referralNote)}
												className=' py-2 px-4 bg-[#000000] text-[#fff] rounded-[5px] md:hidden flex items-center space-x-[6px]'
											>
												<img src={copy} alt='' />
												<p className='text-sm md:text-base font-Inter font-medium'>Copy</p>
											</button>
										)}
										<div className=' flex items-center'>
											{isLoading ? (
												<SkeletonTheme color='#202020' highlightColor='#aaa'>
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															gap: '10px',
														}}
													>
														<Skeleton height={20} width={45} count={1} />
														{/* <Skeleton height={20} width={45} count={1} />
														<Skeleton height={20} width={45} count={1} /> */}
													</div>
												</SkeletonTheme>
											) : (
												<>
													<button className='hidden py-2 px-4 rounded-[5px] bg-[#fff] items-center space-x-[6px]'>
														<img src={thumbsUp} alt='like' />
														<p className=' font-Inter text-sm md:text-base font-medium text-[#272D37]'>Like</p>
													</button>
													<button className='hidden py-2 pl-4 md:pr-4 rounded-[5px] bg-[#fff] items-center space-x-[6px]'>
														<img src={thumbsDown} alt='Dislike' />
														<p className=' font-Inter text-base font-medium text-[#272D37]'>Dislike</p>
													</button>
													<button
														onClick={() => copyToClipboard(referralNote)}
														className=' py-2 px-4 bg-[#000000] text-[#fff] rounded-[5px] hidden md:flex items-center space-x-[6px]'
													>
														<img src={copy} alt='' />
														<p className='text-sm md:text-base font-Inter font-medium'>Copy</p>
													</button>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					{/* Refined Transcript */}
					{/* <div className='hidden w-full shadow-sm rounded-[5px] border border-[#EAEBF0] shadow-[#1018280A] mt-4'>
						<div className='w-full p-5 flex flex-row justify-between items-center'>
							{isLoading ? (
								<SkeletonTheme color='#202020' highlightColor='#aaa'>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Skeleton height={20} width={140} count={1} />
									</div>
								</SkeletonTheme>
							) : (
								<p className='  text-base font-BricolageGrotesque font-semibold text-[#272D37]'>Transcript</p>
							)}
							{isLoading ? (
								<SkeletonTheme color='#202020' highlightColor='#aaa'>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Skeleton height={20} width={140} count={1} />
									</div>
								</SkeletonTheme>
							) : (
								<div className='flex items-center justify-between'>
									<button
										onClick={handleReGenerateRefinedTranscript}
										className='hidden mr-1 px-4 py-2 text-[#272d37] font-Inter text-base font-medium text-center shadow-sm shadow-[#1018280A] bg-[#E1EAEA] rounded-[5px]'
									>
										Generate
									</button>
									<img
										src={down}
										alt=''
										className={`cursor-pointer transition-transform duration-300 ${
											isTranscriptExpanded ? 'rotate-180' : 'rotate-0'
										}`}
										onClick={toggleTranscriptAccordion}
									/>
								</div>
							)}
						</div>
						<div className={`transition-all duration-300 ${isTranscriptExpanded ? 'block ' : 'hidden'}`}>
							<div className=' border-[#EAEBF0] border-y py-4 px-5'>
								<div className=' text-[#5F6D7E] font-Inter font-normal text-base'>
									{isLoading ? (
										<SkeletonTheme color='#202020' highlightColor='#aaa'>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<Skeleton height={30} count={1} />
											</div>
										</SkeletonTheme>
									) : (
										<div
											className='markdown-container px-2'
											onBlur={handleTranscriptBlur}
											contentEditable={isTranscriptEditing}
											suppressContentEditableWarning={true}
											ref={transcriptRef}
										>
											<ReactMarkdown
												children={refTranscript ? refTranscript : 'Transcript not found'}
												remarkPlugins={[remarkGfm]}
												rehypePlugins={[rehypeRaw]}
												components={{
													strong: ({ node, ...props }) => <strong className='bold-text' {...props} />,
													p: ({ node, ...props }) => <p className='paragraph-text' {...props} />,
												}}
											/>
										</div>
									)}
								</div>
							</div>
							<div className=' py-4 px-5 flex items-center'>
								{isLoading ? (
									<SkeletonTheme color='#202020' highlightColor='#aaa'>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												gap: '10px',
											}}
										>
											<Skeleton height={20} width={70} count={1} />
										</div>
									</SkeletonTheme>
								) : (
									<button
										onClick={startEditingTranscript}
										className=' px-4 py-2 text-[#272d37] font-Inter text-base font-medium text-center shadow-sm shadow-[#1018280A] border border-[#DAE0E6] rounded-[5px]'
									>
										{isTranscriptEditing ? 'Stop Editing' : 'Edit'}
									</button>
								)}
							</div>
						</div>
					</div> */}
					{/* Raw Transcript */}
					<div className='w-full shadow-sm rounded-[5px] border border-[#EAEBF0] shadow-[#1018280A] mt-4'>
						<div
							onClick={toggleRawTranscriptAccordion}
							className='w-full p-5 flex flex-row justify-between items-center cursor-pointer hover:bg-gray-100'
						>
							{isLoading ? (
								<SkeletonTheme color='#202020' highlightColor='#aaa'>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Skeleton height={20} width={140} count={1} />
									</div>
								</SkeletonTheme>
							) : (
								<p className='text-sm md:text-base font-BricolageGrotesque font-semibold text-[#272D37]'>
									Raw Transcript
								</p>
							)}
							<img
								src={down}
								alt=''
								className={`cursor-pointer transition-transform duration-300 ${
									isRawTranscriptExpanded ? 'rotate-180' : 'rotate-0'
								}`}
							/>
						</div>
						<div className={`transition-all duration-300 ${isRawTranscriptExpanded ? 'block ' : 'hidden'}`}>
							<div className=' border-[#EAEBF0] border-y py-4 px-5'>
								<div className=' text-[#5F6D7E] font-Inter font-normal text-base'>
									{isLoading ? (
										<SkeletonTheme color='#202020' highlightColor='#aaa'>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<Skeleton height={30} count={1} />
											</div>
										</SkeletonTheme>
									) : (
										<div
											className='markdown-container px-2'
											// onBlur={handleRawTranscriptBlur}
											contentEditable={isRawTranscriptEditing}
											suppressContentEditableWarning={true}
											ref={transcriptRawRef}
										>
											<ReactMarkdown
												children={rawTranscript ? rawTranscript : 'Raw transcript not found'}
												remarkPlugins={[remarkGfm]}
												rehypePlugins={[rehypeRaw]}
												components={{
													strong: ({ node, ...props }) => <strong className='bold-text' {...props} />,
													p: ({ node, ...props }) => <p className='paragraph-text' {...props} />,
												}}
											/>
										</div>
									)}
								</div>
							</div>
							<div className=' py-4 px-5 flex items-center'>
								{isLoading ? (
									<SkeletonTheme color='#202020' highlightColor='#aaa'>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												gap: '10px',
											}}
										>
											<Skeleton height={20} width={70} count={1} />
										</div>
									</SkeletonTheme>
								) : (
									<button
										onClick={startEditingRawTranscript}
										className='hidden px-4 py-2 text-[#272d37] font-Inter text-base font-medium text-center shadow-sm shadow-[#1018280A] border border-[#DAE0E6] rounded-[5px]'
									>
										{isRawTranscriptEditing ? 'Stop Editing' : 'Edit'}
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='hidden fixed bottom-0 w-full lg:w-[80%] left-0 lg:left-[20%] px-4 md:px-12 pb-8 bg-white '>
				<div className='w-full relative'>
					<input
						className=' bg-[#F5F5F5] pl-[52px] pr-6 py-4 rounded-[50px] w-full relative border border-[#EAEBF0] font-Inter font-normal text-base text-black placeholder:text-[#282D2D]'
						type='text'
						placeholder='Interact with Note'
					/>
					<img src={mic} className=' absolute left-6 top-[18.5px]' alt='' />
					<img src={paperPlane} className=' absolute right-6 top-[18.5px]' alt='' />
				</div>
			</div>
		</>
	);
};

export default TranscribedUi;

