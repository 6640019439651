import { useEffect, useState } from "react";
import close from "./assets/close cross.svg";

const Transactions = ({ closeTransactionsModal }) => {
  const transactions = [
    {
      id: 1,
      type: "Subscription",
      amount: "$99",
      date: "Wed 1:00 pm",
      status: "Completed",
    },
    {
      id: 2,
      type: "Subscription",
      amount: "$99",
      date: "Thu 2:30 pm",
      status: "Pending",
    },
    {
      id: 3,
      type: "Subscription",
      amount: "$99",
      date: "Fri 11:00 am",
      status: "Completed",
    },
    {
      id: 4,
      type: "Subscription",
      amount: "$99",
      date: "Sat 5:00 pm",
      status: "Pending",
    },
  ];

  const [doraToken, setDoraToken] = useState(null);
  const [userTransactions, setUserTransactions] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Get the item from localStorage
    const storedItem = localStorage.getItem("dora_token");
    // Set the token if it exists
    if (storedItem) {
      setDoraToken(storedItem);
    }
  }, []);

  // get user transactions
  useEffect(() => {
    // Only fetch user transactions if doraToken is available
    if (doraToken) {
      const url = new URL(`${API_BASE_URL}api/v1/transactions`);

      const headers = {
        Authorization: `Bearer ${doraToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      fetch(url, {
        method: "GET",
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setUserTransactions(data.data || {});
        })
        .catch((error) => {
          console.error("Error fetching user transactions:", error);
        });
    }
  }, [doraToken]);

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JS
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  // Calculate the total number of pages
  const totalPages = Math.ceil(transactions.length / itemsPerPage);

  // Calculate the items to display based on the current page
  const currentItems = userTransactions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
		<>
			<div className=' w-full md:w-[120%] h-full bg-[#1212128d] z-[99999] fixed top-0 md:-left-[20%] px-6 flex justify-center items-center'>
				<div className='w-full flex justify-center items-center'>
					<div className='md:ml-[20%] bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] h-[470px] p-6 rounded-[15px] w-full md:w-[750px]'>
						<div className='w-full h-full overflow-y-auto bg-white'>
							<span className=' flex items-center justify-between'>
								<p className=' text-[#272D37] font-semibold font-BricolageGrotesque text-xl'>Transactions</p>
								<img src={close} className='cursor-pointer' onClick={closeTransactionsModal} alt='' />
							</span>
							<p className=' font-Inter text-[#5F6D7E] font-normal text-base mt-2'>
								See all your transaction history here.
							</p>

							<div className='overflow-x-auto mt-6'>
								{userTransactions.length === 0 ? (
									<p className='text-center font-BricolageGrotesque text-base font-normal text-[#A1A1A1] py-4'>
										There are no transactions.
									</p>
								) : (
									<>
										<table className='min-w-full bg-white text-left'>
											<thead>
												<tr>
													<th className=' py-2 md:py-3 px-4 md:px-6 text-[#5F6D7E] font-BricolageGrotesque font-medium text-[13px] border-y border-[#EAEBF0] text-left'>
														Transaction Type
													</th>
													<th className=' py-2 md:py-3 px-4 md:px-6 text-[#5F6D7E] font-BricolageGrotesque font-medium text-[13px] border-y border-[#EAEBF0] text-left'>
														Amount
													</th>
													<th className=' py-2 md:py-3 px-4 md:px-6 text-[#5F6D7E] font-BricolageGrotesque font-medium text-[13px] border-y border-[#EAEBF0] text-left'>
														Date
													</th>
													<th className=' py-2 md:py-3 px-4 md:px-6 text-[#5F6D7E] font-BricolageGrotesque font-medium text-[13px] border-y border-[#EAEBF0] text-left'>
														Status
													</th>
												</tr>
											</thead>
											<tbody>
												{currentItems.map((transaction) => (
													<tr key={transaction.id}>
														<td className=' py-2 md:py-5 px-4 md:px-6 border-b font-Inter text-black font-medium text-sm border-[#EAEBF0] text-left'>
															{transaction.type}
														</td>
														<td className=' py-2 md:py-5 px-4 md:px-6 border-b font-Inter text-black font-medium text-sm border-[#EAEBF0] text-left'>
															${transaction.amount}
														</td>
														<td className=' py-2 md:py-5 px-4 md:px-6 border-b font-Inter text-black font-medium text-sm border-[#EAEBF0] text-left'>
															{formatDateString(transaction.created_at)}
														</td>
														<td className=' py-2 md:py-5 px-4 md:px-6 border-b font-Inter font-base text-sm border-[#EAEBF0] text-left'>
															{/* <span
                                className={`px-[10px] py-2 rounded-full ${
                                  transaction.status === "Completed"
                                    ? "bg-[#17BD8D1A] text-[#17BD8D]"
                                    : "bg-[#faaf001a] text-[#faaf00]"
                                }`}
                              >
                                {transaction.status}
                              </span> */}
															<button
																className={`px-[10px] py-2 font-normal rounded-[20px] ${
																	transaction.status ? 'bg-[#17BD8D1A] text-[#17BD8D]' : 'bg-[#faaf001a] text-[#faaf00]'
																}`}
															>
																{transaction.status ? transaction.status : 'Pending'}
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										{/* Pagination Controls */}
										<div className='flex justify-center items-center mt-4'>
											<button
												className='px-2 py-1 text-sm font-Inter mx-1 text-[#00aaaa] bg-[#E1EAEA] rounded'
												onClick={() => handlePageChange(currentPage - 1)}
												disabled={currentPage === 1}
											>
												Prev
											</button>
											<span className='text-sm font-Inter mx-2'>
												Page {currentPage} of {totalPages}
											</span>
											<button
												className='px-2 py-1 text-sm font-Inter mx-1 text-[#00aaaa] bg-[#E1EAEA] rounded'
												onClick={() => handlePageChange(currentPage + 1)}
												disabled={currentPage === totalPages}
											>
												Next
											</button>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Transactions;

