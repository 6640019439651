import { useContext, useEffect } from "react";
import HeadBar from "./components/headBar";
import Home from "./components/home";
import Sidebar from "./components/sidebar";
import {
  ActivePageContext,
  SidebarContext,
} from "./contexts/ActivePageContext";
import Transcribing from "./components/transcribing";
import TranscribedUi from "./components/transcribedUI";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const componentMap = {
    Home: <Home />,
    Transcribe: <Transcribing />,
    Transcribed: <TranscribedUi />,
  };

  const Navigate = useNavigate();

  useEffect(() => {
    const storedItem = localStorage.getItem("dora_token");

    // Check if the token exists
    if (storedItem) {
      return;
    } else {
      // Navigate to the sign-in page if the token doesn't exist
      Navigate("/login");
    }
  }, [Navigate]);

  const { activePage } = useContext(ActivePageContext);
  const { setSidebarVisible } = useContext(SidebarContext);

  useEffect(() => {
    setSidebarVisible(false);
  }, [activePage, setSidebarVisible]);

  const ComponentToRender = componentMap[activePage] || null;

  return (
    <>
      <div className="">
        <Sidebar />
        <HeadBar />
        {ComponentToRender}
      </div>
    </>
  );
};

export default Dashboard;
