import { useEffect, useState } from "react";
import logo from "./assets/Logo.svg";
import shorts from "./assets/shorts.svg";
import { Link, useNavigate } from "react-router-dom";
import { handleVerifyMail } from "../../controllers/UserController";

const EmailVerification = () => {
  const navigate = useNavigate();

  const [otp, setOTP] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("Verifying...");

  const onSuccess = (response) => {
    if (response) {
      setVerificationStatus("Your mail has been verified successfully");
      setTimeout(() => navigate("/login"), 3000); // Navigate after 2 seconds
    } else {
      setVerificationStatus("Verification failed. Please try again.");
    }
  };

  useEffect(() => {
    // Parse the URL to get the query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("email");
    const tokenParam = urlParams.get("access_code");

    // Set the states
    setEmail(emailParam);
    setOTP(tokenParam);
  }, []);

  useEffect(() => {
    // Verify email if both email and otp are set
    if (email && otp) {
      const userData = { email, otp };
      handleVerifyMail(userData, onSuccess);
    }
  }, [email, otp]);

  return (
    <>
      <div className="w-full h-[100vh] flex justify-center items-center bg-[#f1f1f1] text-[#000]">
        <div className="p-6 w-full max-w-[1280px] lg:max-h-[630px] bg-[#fff] h-full flex rounded-[15px] flex-row justify-center lg:justify-between">
          <div className="w-full lg:w-[49%] relative h-full flex flex-col items-center justify-center">
            <Link to="/" className="cursor-pointer">
              <img src={logo} alt="Dorascribe" />
            </Link>
            <p className="mt-6 font-BricolageGrotesque font-semibold text-2xl text-black">
              Email Verification
            </p>
            <p className="mt-4 text-center text-base font-normal text-black font-Inter">
              {verificationStatus}
            </p>
          </div>
          <div
            style={{
              backgroundImage: `url(${shorts})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className="w-full lg:w-[49%] bg-[#f1f1f1] rounded-[20px] relative hidden lg:flex flex-col justify-center"
          ></div>
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
