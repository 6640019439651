import React, { useState, useEffect } from "react";
import logo from "./assets/Logo.svg";
import shorts from "./assets/shorts.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  handleEmailOTPVerification,
  handleResendOTPEmail,
} from "../../controllers/AuthenticateController";

const Authenticate = () => {
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const navigate = useNavigate();

  // Effect to retrieve email from localStorage on component mount
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const onSuccess = (response) => {
    console.log("Registration successful:", response);
    navigate("/chooseplan"); // Redirect to dashboard on success
  };

  const handleVerification = async () => {
    const userData = { email, otp };

    handleEmailOTPVerification(userData, onSuccess);
  };

  const handleResend = async () => {
    const userData = { email };

    handleResendOTPEmail(userData);
  };

  return (
    <div className="w-full h-[100vh] flex justify-center items-center bg-[#f1f1f1] text-[#000]">
      <div className="p-6 w-full max-w-[1280px] lg:max-h-[630px] bg-[#fff] h-full flex rounded-[15px] flex-row justify-center lg:justify-between">
        <div className="w-full lg:w-[49%] relative h-full flex flex-col items-center justify-center">
          <Link to="/" className="cursor-pointer">
            <img src={logo} alt="Dorascribe" />
          </Link>
          <p className="mt-6 font-BricolageGrotesque font-semibold text-2xl text-black">
            We sent you a code
          </p>
          <p className="mt-1 text-[#282D2D] font-Inter font-normal text-lg">
            Check your email
          </p>

          <input
            type="text"
            className="mt-3 border border-[#EAEBF0] w-full md:w-[500px] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
            placeholder="Enter Email"
            value={email}
            readOnly
          />
          <input
            type="text"
            className="mt-3 border border-[#EAEBF0] w-full md:w-[500px] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
            placeholder="Enter Code"
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
          />
          <div className="w-full md:w-[500px] mt-6 flex items-center justify-between">
            <button
              className="w-[34%] rounded-[50px] bg-[#000] text-base font-semibold text-white font-BricolageGrotesque py-4"
              onClick={handleResend}
            >
              Resend
            </button>
            <button
              className="w-[64%] rounded-[50px] bg-[#0dabab] text-base font-semibold text-white font-BricolageGrotesque py-4"
              onClick={handleVerification}
            >
              Proceed
            </button>
          </div>
          <p className="mt-3 text-center text-[#282D2D] font-Inter font-normal text-base">
            By signing up, you agree to the terms of Use, Privacy Policy.
          </p>

          <p className="absolute bottom-0 text-center text-[#12121266] font-Inter text-base font-normal">
            Don't have an Account?{" "}
            <Link to="/signUp">
              <span className="text-[#0DABAB] font-semibold">Sign up</span>
            </Link>
          </p>
        </div>
        <div
          style={{
            backgroundImage: `url(${shorts})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="w-full lg:w-[49%] bg-[#f1f1f1] rounded-[20px] relative hidden lg:flex flex-col justify-center"
        ></div>
      </div>
    </div>
  );
};

export default Authenticate;
