import api from '../api';

export const handleEmailOTPVerification = async (userData, onSuccess) => {
	try {
		const response = await api('post', '/auth/email-otp-verify', userData);
		console.log('response: ', response);
		onSuccess(response);
	} catch (error) {
		throw error;
	}
};

export const handleResendOTPEmail = async (userEmail) => {
	try {
		return await api('post', '/auth/resend-otp-email', userEmail);
	} catch (error) {
		throw error;
	}
};
