import { useState } from "react";
import { handleUserRegistrationWithOTP } from "../../controllers/UserController";
import { useNavigate } from "react-router-dom";

const SignUPOTP = ({ specialties }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [specialty_id, setSpecialty_id] = useState("");
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate("/authenticate");
  };

  const handleSignUpClick = () => {
    const userData = { email, name, specialty_id };
    handleUserRegistrationWithOTP(userData, onSuccess);
  };

  return (
    <>
      <div className="  w-full md:w-[500px] flex items-center justify-between">
        <input
          type="text"
          className="mt-3 border border-[#EAEBF0] w-[48%] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
          placeholder="Enter your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className=" w-[48%] mt-3 border border-[#EAEBF0] rounded-[50px]  px-6 py-4">
          <select
            className="w-full bg-transparent font-Inter font-normal text-sm text-[#282D2D] custom-select"
            value={specialty_id}
            onChange={(e) => setSpecialty_id(e.target.value)}
          >
            <option value="">Select Specialty</option>
            {specialties.map((specialty) => (
              <option key={specialty.id} value={specialty.id}>
                {specialty.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <input
        type="text"
        className="mt-3 border border-[#EAEBF0] w-full md:w-[500px] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
        placeholder="Enter your email address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <button
        className="w-full md:w-[500px] rounded-[50px] bg-[#0dabab] text-base font-semibold text-white font-BricolageGrotesque py-4 mt-6"
        onClick={handleSignUpClick}
      >
        Proceed
      </button>
    </>
  );
};

export default SignUPOTP;
