import { useContext, useEffect, useState } from "react";
import logo from "./assets/Logo.svg";
import shorts from "./assets/shorts.svg";
import { Link, useNavigate } from "react-router-dom";
import { PlanPriceIdContext } from "./contexts/ActivePageContext";
import load from "./assets/load.gif";

const TrialEnd = () => {
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { planPriceId, setPlanPriceId } = useContext(PlanPriceIdContext);
  const Navigate = useNavigate();
  const [doraToken, setDoraToken] = useState(null);
  const [profileDetails, setProfileDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [activePeriod, setActivePeriod] = useState("Month");
  const [isLoading, setIsLoading] = useState(false); // Loading state

  useEffect(() => {
    const storedItem = localStorage.getItem("dora_token");
    if (storedItem) {
      setDoraToken(storedItem);
    }
  }, []);

  useEffect(() => {
    if (doraToken) {
      const url = new URL(`${API_BASE_URL}api/v1/profile`);
      const headers = {
        Authorization: `Bearer ${doraToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      fetch(url, {
        method: "GET",
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setProfileDetails(data.data || {});
          const currentPlanName = data.data?.plan;
          if (currentPlanName) {
            const matchedPlan = plans.find(
              (item) => item.name === currentPlanName
            );
            if (matchedPlan) {
              setPlan(currentPlanName);
              setSelectedPlan(matchedPlan);
              setPlanPriceId(matchedPlan.id);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching profile details:", error);
        });
    }
  }, [doraToken, plans]);

  useEffect(() => {
    const url = new URL(`${API_BASE_URL}api/v1/plans`);
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setPlans(data.data);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      });
  }, []);

  const handlePlanSelection = (selectedPlanName, selectedPeriodicity) => {
    const selected = plan !== selectedPlanName;
    setPlan(selected ? selectedPlanName : "");
    if (selected) {
      const selectedPlan = plans.find(
        (plan) =>
          plan.name === selectedPlanName &&
          plan.periodicity_type === selectedPeriodicity
      );
      setSelectedPlan(selectedPlan);
      setPlanPriceId(selectedPlan?.id); // Use optional chaining to prevent errors
    } else {
      setSelectedPlan(null);
    }
  };

  const handleCheckout = async () => {
    setIsLoading(true); // Start loading
    try {
      const checkoutResponse = await fetch(
        `${API_BASE_URL}api/v1/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${doraToken}`,
            Accept: "application/json",
          },
          body: JSON.stringify({
            plan_id: planPriceId,
          }),
        }
      );

      if (!checkoutResponse.ok) {
        setErrorMessage("Failed to create checkout session");
        throw new Error("Failed to create checkout session");
      }

      const checkoutData = await checkoutResponse.json();
      console.log("Checkout session created successfully:", checkoutData);

      window.location.href = checkoutData.data.message;
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setErrorMessage("An error occurred while creating checkout session");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  // / Filter plans based on the activePeriod
  const filteredPlans = plans.filter(
    (item) =>
      item.periodicity_type === activePeriod || item.name === "Free Trial" // Show the trial plan regardless of periodicity
  );

  const handleLodOut = () => {
    localStorage.clear();
    Navigate("/"); // Redirect to home
  };

  return (
    <div className="w-full h-[100vh] flex justify-center items-center bg-[#f1f1f1] text-[#000]">
      <div className="p-6 w-full max-w-[1280px] lg:max-h-[630px] bg-[#fff] h-full flex rounded-[15px] flex-row justify-center lg:justify-between">
        <div className="w-full lg:w-[49%] relative h-full flex flex-col items-center justify-center">
          <Link to="/" className="cursor-pointer">
            <img src={logo} alt="Dorascribe" />
          </Link>
          <p className="mt-6 font-BricolageGrotesque font-semibold text-2xl text-black">
            Your free trial has expired please upgrade
          </p>

          <div className="p-1 rounded-[60px] bg-[#F7F7F8] border border-[#EAEBF0] mt-3 w-full md:w-[500px] flex flex-row space-x-[6px] items-center">
            <button
              className={`w-[48%] py-3 px-8 md:px-16 rounded-[25px] shadow-sm shadow-[#1018280A] font-BricolageGrotesque font-semibold text-sm ${
                activePeriod === "Month"
                  ? "bg-[#00aaaa] text-white"
                  : "text-[#929292]"
              }`}
              onClick={() => {
                setActivePeriod("Month");
                setPlan(""); // Clear the selected plan
                setSelectedPlan(null); // Reset the selected plan state
              }}
            >
              Monthly
            </button>
            <button
              className={`w-[48%] rounded-[25px] py-3 px-8 md:px-16 font-BricolageGrotesque font-semibold text-sm ${
                activePeriod === "Year"
                  ? "bg-[#00aaaa] text-white"
                  : "text-[#929292]"
              }`}
              onClick={() => {
                setActivePeriod("Year");
                setPlan(""); // Clear the selected plan
                setSelectedPlan(null); // Reset the selected plan state
              }}
            >
              Yearly
            </button>
          </div>

          <div className="flex flex-col space-y-3 mt-6 w-full md:w-[500px]">
            {filteredPlans.map((item, index) => (
              <div
                key={index}
                className={`border w-full rounded-[8px] p-4 mb-3 flex flex-row justify-between cursor-pointer ${
                  plan === item.name ? "border-[#00aaaa]" : "border-[#EAECF0]"
                }`}
                onClick={() =>
                  handlePlanSelection(item.name, item.periodicity_type)
                }
              >
                <span>
                  <p className="font-BricolageGrotesque text-sm font-semibold text-black">
                    {item.name}
                  </p>
                  <p className="text-[#919BA7] mt-1 font-Inter text-sm font-normal">
                    ${item.price}{" "}
                    {item.name === "Free Trial"
                      ? `for ${item.periodicity} days Trial`
                      : `Per ${item.periodicity_type}`}
                  </p>
                </span>
                <div className="round">
                  <input
                    type="checkbox"
                    id={item.name}
                    readOnly
                    checked={plan === item.name}
                  />
                  <label htmlFor={item.name}></label>
                </div>
              </div>
            ))}
          </div>

          <div className="flex items-center justify-between w-full md:w-[500px] mt-6">
            <button
              onClick={handleLodOut}
              className="rounded-[50px] text-base font-semibold text-white font-BricolageGrotesque py-4 bg-[#000000] w-[28%]"
            >
              Logout
            </button>
            <button
              className={`rounded-[50px] w-[70%] text-base font-semibold text-white font-BricolageGrotesque py-4 ${
                selectedPlan && selectedPlan.name === "Professional"
                  ? "bg-[#00aaaa]"
                  : "bg-[#B6E6E6]"
              }`}
              onClick={handleCheckout}
              disabled={
                !selectedPlan ||
                selectedPlan.name !== "Professional" ||
                isLoading
              } // Disable button while loading
            >
              {isLoading ? (
                <img src="" className=" w-6 h-6 mx-auto" alt="" />
              ) : (
                "Proceed"
              )}
            </button>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${shorts})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="w-full lg:w-[49%] bg-[#f1f1f1] rounded-[20px] relative hidden lg:flex flex-col justify-center"
        ></div>
      </div>
    </div>
  );
};

export default TrialEnd;
