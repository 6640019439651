import close from "./assets/close cross.svg";

const EditTemp = ({
  noteTitle,
  selectedOption,
  onCloseNote,
  onProceedNoteChange,
}) => {
  return (
    <>
      <div className=" w-full md:w-[120%] h-full bg-[#1212128d] z-[99999] fixed top-0 md:-left-[20%] px-6 flex justify-center items-center">
        <div className="w-full flex justify-center items-center">
          <div className="md:ml-[20%] bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] h-[270px] p-6 rounded-[15px] w-full md:w-[400px]">
            <div className="w-full h-full overflow-y-auto bg-white">
              <span className=" flex items-center justify-between">
                <p className=" font-BricolageGrotesque font-semibold text-xl text-[#272D37]">
                  Notification
                </p>
                <img
                  src={close}
                  className="cursor-pointer"
                  onClick={onCloseNote}
                  alt=""
                />
              </span>
              <div className=" mt-6">
                <p className=" mt-2 font-Inter font-normal text-[#5F6D7E] text-sm">
                  Are you sure you want to override the{" "}
                  <strong style={{ fontFamily: "arial", color: "#000" }}>
                    {noteTitle}
                  </strong>{" "}
                  to &nbsp;
                  <strong style={{ fontFamily: "arial", color: "#000" }}>
                    {selectedOption.name}
                  </strong>
                  .
                </p>
                <p className=" mt-2 font-Inter font-normal text-[#5F6D7E] text-sm">
                  Please note that it will clear up your{" "}
                  <strong style={{ fontFamily: "arial", color: "#000" }}>
                    {noteTitle}
                  </strong>{" "}
                  drafts
                </p>
                <button
                  onClick={onProceedNoteChange}
                  className=" mt-6 w-full flex items-center bg-[#00AAAA] rounded-[30px] text-white font-BricolageGrotesque font-semibold text-base justify-center text-center py-3"
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTemp;
