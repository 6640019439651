import close from "./assets/close cross.svg";

const EditTemp = ({ onCloseEditTemplate }) => {
  return (
    <>
      <div className=" w-full md:w-[120%] h-full bg-[#1212128d] z-[99999] fixed top-0 md:-left-[20%] px-6 flex justify-center items-center">
        <div className="w-full flex justify-center items-center">
          <div className="md:ml-[20%] bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] h-[420px] p-6 rounded-[15px] w-full md:w-[400px]">
            <div className="w-full h-full overflow-y-auto bg-white">
              <span className=" flex items-center justify-between">
                <p className=" font-BricolageGrotesque font-semibold text-xl text-[#272D37]">
                  Custom templates 1
                </p>
                <img
                  src={close}
                  className="cursor-pointer"
                  onClick={onCloseEditTemplate}
                  alt=""
                />
              </span>
              <div className=" mt-6">
                <label
                  className=" flex w-full flex-col text-[#272D37] font-semibold font-BricolageGrotesque text-sm"
                  htmlFor=""
                >
                  Name
                  <input
                    className=" mt-[6px] border border-[#DAE0E6] font-Inter text-sm font-normal rounded-[5px] p-2.5"
                    type="text"
                    placeholder="Enter Template Name"
                  />
                </label>
                <label
                  className=" flex w-full flex-col text-[#272D37] font-semibold font-BricolageGrotesque text-sm mt-6"
                  htmlFor=""
                >
                  Instruction
                  <textarea
                    className="mt-[6px] border border-[#DAE0E6] font-Inter text-sm font-normal rounded-[5px] p-2.5"
                    name=""
                    placeholder="Enter Instruction"
                    id=""
                  ></textarea>
                </label>
                <p className=" mt-2 font-Inter font-normal text-[#5F6D7E] text-sm">
                  This instruction will be used as an output to your generated
                  note
                </p>
                <button className=" mt-6 w-full flex items-center bg-[#00AAAA] rounded-[30px] text-white font-BricolageGrotesque font-semibold text-base justify-center text-center py-3">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTemp;
