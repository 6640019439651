import { useState, useEffect, useCallback } from 'react';

const useLocalStorageListener = (key, initialValue) => {
	const [value, setValue] = useState(() => {
		const storedValue = localStorage.getItem(key);
		return storedValue !== null ? JSON.parse(storedValue) : initialValue;
	});

	const setStoredValue = useCallback(
		(newValue) => {
			setValue(newValue);
			localStorage.setItem(key, JSON.stringify(newValue));
		},
		[key]
	);

	useEffect(() => {
		const handleStorageChange = (event) => {
			if (event.key === key) {
				setValue(event.newValue ? JSON.parse(event.newValue) : initialValue);
			}
		};

		window.addEventListener('storage', handleStorageChange);

		const originalSetItem = localStorage.setItem;
		localStorage.setItem = function (setItemKey, setItemValue) {
			originalSetItem.apply(this, arguments);
			if (setItemKey === key) {
				window.dispatchEvent(
					new StorageEvent('storage', {
						key: setItemKey,
						newValue: setItemValue,
					})
				);
			}
		};

		return () => {
			window.removeEventListener('storage', handleStorageChange);
			localStorage.setItem = originalSetItem;
		};
	}, [key, initialValue]);

	return [value, setStoredValue];
};

export default useLocalStorageListener;
