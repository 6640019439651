import React, { useEffect, useRef, useContext, useState, useCallback } from 'react';
import { io } from 'socket.io-client';
import trans from './assets/tans.svg';
import stop from './assets/StopCircle.svg';
import pause from './assets/pause-white.svg';
import play from './assets/Play-white.svg';
import trash from './assets/trash.svg';
import { ActivePageContext } from '../contexts/ActivePageContext';
import { handleGetNoteById, handleUserUpdateNote } from '../../../controllers/NoteController';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useLocalStorageListener from '../hooks/useLocalStorageListener';
import WaveAnimation from './WaveAnimation';
// import ConfirmationModal from './ConfirmationModal';
import { useNavigate } from 'react-router-dom';

const Transcribing = () => {
	const [socket, setSocket] = useState(null); // State to manage socket instance
	const [email, setEmail] = useState('');
	const [connected, setConnected] = useState(false); // State to track socket connection
	const { setActivePage } = useContext(ActivePageContext);

	const [time, setTime] = useState(0);
	const [isActive, setIsActive] = useState(false);
	const [isPaused, setIsPaused] = useState(true);
	const [isDeepgramConnect, setIsDeepgramConnect] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	// const [noteId, setNoteId] = useState('');
	const [noteId, setNoteId] = useLocalStorageListener('note_id', null);
	const [patientInfo, setPatientInfo] = useLocalStorageListener('patient_info', null);
	const [transcriptFinalized, setTranscriptFinalized] = useState({
		transcript: '',
	});
	const [isOnline, setIsOnline] = useState(window.navigator.onLine);
	const countRef = useRef(null);
	const microphoneRef = useRef(null);
	const streamRef = useRef(null);
	const contentEditableRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	// const [isModalVisible, setModalVisible] = useState(false);
	// const [isDisableButton, setDisableButton] = useState(false);
	// const [isConfirmed, setIsConfirmed] = useState(false);
	const [rawTranscript, setRawTranscript] = useState('');
	const navigate = useNavigate();

	const fetchNoteData = useCallback(
		(id) => {
			setIsLoading(true);

			handleGetNoteById(id)
				.then((response) => {
					const data = response.data.data;
					const patientData = { name: data.patient_name, pronoun: data.patient_pronoun };

					if (data) setIsLoading(false);
					setPatientInfo(patientData);

					const rawTranscriptionContent = data?.raw_transcription;
					setRawTranscript(rawTranscriptionContent);
				})
				.catch((error) => {
					console.error('Error fetching note data:', error);
				});
		},
		[setPatientInfo]
	);

	useEffect(() => {
		const storedNoteId = localStorage.getItem('note_id');
		setNoteId(JSON.parse(storedNoteId));
	}, [setNoteId]);

	useEffect(() => {
		if (noteId !== null) {
			fetchNoteData(noteId);
		}
	}, [fetchNoteData, noteId]);

	useEffect(() => {
		const handleOnline = () => setIsOnline(true);
		const handleOffline = () => setIsOnline(false);

		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, []);

	useEffect(() => {
		// const doraSocket = io('http://localhost:8080');
		const doraSocket = io('https://deepgram-websocket.dorascribe.ai/');

		setEmail(localStorage.getItem('email'));

		doraSocket.on('connect', () => {
			setConnected(true);
			// console.log('Connected to Socket.io server');
			if (doraSocket) {
				doraSocket.emit('registerUser', { email });
			} else {
				console.log('Socket is not connected.');
			}
		});

		doraSocket.on('deepgramConnected', (boolParams) => {
			// console.log(`_connected: ${boolParams}`);
			setIsDeepgramConnect(boolParams);
		});

		doraSocket.on('transcriptData', (transcript) => {
			const newText = transcript;
			setTranscriptFinalized((prev) => ({
				transcript: prev.transcript + ' ' + newText,
			}));
		});

		doraSocket.on('disconnect', () => {
			setConnected(false);
		});

		setSocket(doraSocket);

		return () => {
			if (doraSocket) {
				doraSocket.disconnect();
			}
		};
	}, [email]);

	useEffect(() => {
		let finalTrans = transcriptFinalized.transcript;
		if (finalTrans) {
			let objData = {
				action: 'raw_transcription',
				content: contentEditableRef.current ? contentEditableRef.current.innerHTML : null,
				// content: transcriptFinalized.transcript,
			};

			handleUserUpdateNote(noteId, objData);
		}
	}, [noteId, transcriptFinalized]);

	useEffect(() => {
		return () => {
			clearInterval(countRef.current);
			if (microphoneRef.current) {
				microphoneRef.current.stop();
			}
			if (streamRef.current) {
				stopStream(streamRef.current);
			}
			if (socket) {
				socket.close();
			}
		};
	}, [socket]);

	useEffect(() => {
		const storedPatientInfo = localStorage.getItem('patient_info');
		setPatientInfo(JSON.parse(storedPatientInfo));
	}, [setPatientInfo]);

	// Effect to retrieve note_id from localStorage on component mount
	useEffect(() => {
		const tempNoteId = localStorage.getItem('note_id');
		if (tempNoteId) {
			setIsLoading(false);
			setNoteId(JSON.parse(tempNoteId));
		}
	}, [setNoteId]);

	const getMicrophone = async () => {
		if (!streamRef.current) {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					audio: true,
				});
				streamRef.current = stream;
			} catch (error) {
				console.error('Error accessing microphone:', error);
				throw error;
			}
		}

		let options = { mimeType: 'audio/webm' };

		if (!MediaRecorder.isTypeSupported(options.mimeType)) {
			if (MediaRecorder.isTypeSupported('audio/mp4')) {
				options = { mimeType: 'audio/mp4' };
			} else if (MediaRecorder.isTypeSupported('audio/wav')) {
				options = { mimeType: 'audio/wav' };
			} else {
				console.error('No supported MIME type found for MediaRecorder.');
				throw new Error('No supported MIME type found for MediaRecorder.');
			}
		}

		return new MediaRecorder(streamRef.current, options);
	};

	const stopStream = (stream) => {
		stream.getTracks().forEach((track) => track.stop());
		streamRef.current = null;
	};

	const openMicrophone = (microphone) => {
		return new Promise((resolve) => {
			microphone.onstart = () => {
				document.body.classList.add('recording');
				resolve();
			};

			microphone.onstop = () => {
				document.body.classList.remove('recording');
			};

			microphone.ondataavailable = async (event) => {
				if (event.data.size > 0 && connected) {
					socket.emit('audioData', {
						audioChunk: event.data,
						email: email,
					});
				}
			};

			microphone.start(1000);
		});
	};

	const handleStart = async () => {
		setIsActive(true);
		setIsPaused(false);
		countRef.current = setInterval(() => {
			setTime((prevTime) => prevTime + 1);
		}, 1000);

		try {
			const mic = await getMicrophone();
			microphoneRef.current = mic;
			await openMicrophone(mic);
		} catch (error) {
			console.error('Error accessing the microphone', error);
		}
	};

	const handlePause = () => {
		if (microphoneRef.current && microphoneRef.current.state === 'recording') {
			microphoneRef.current.pause();
		} else {
			console.log('Microphone is not recording');
		}
		clearInterval(countRef.current);
		setIsPaused(true);
	};

	const handleResume = () => {
		if (microphoneRef.current && microphoneRef.current.state === 'paused') {
			microphoneRef.current.resume();
		} else {
			console.log('Microphone is not paused');
		}
		countRef.current = setInterval(() => {
			setTime((prevTime) => prevTime + 1);
		}, 1000);
		setIsPaused(false);
	};

	// const handleStop = () => {
	// 	if (microphoneRef.current && microphoneRef.current.state !== 'inactive') {
	// 		microphoneRef.current.stop();
	// 		console.log('Microphone stopped');
	// 	} else {
	// 		console.log('Microphone is not active');
	// 	}
	// 	clearInterval(countRef.current);
	// 	setIsActive(false);
	// 	setIsPaused(false);
	// 	setTime(0);
	// 	stopStream(streamRef.current);
	// };

	const handleStopRecording = () => {
		handleSendToOpenAI();

		if (microphoneRef.current) {
			microphoneRef.current.stop();
		}
		if (streamRef.current) {
			stopStream(streamRef.current);
		}
		if (socket) {
			socket.close();
		}

		clearInterval(countRef.current);
		setIsActive(false);
		setIsPaused(true);
		setTime(0);
		setIsProcessing(true);
		// setIsConfirmed(true);
		// Perform additional actions here upon confirmation
	};

	const formatTime = (time) => {
		const getSeconds = `0${time % 60}`.slice(-2);
		const minutes = Math.floor(time / 60);
		const getMinutes = `0${minutes % 60}`.slice(-2);
		const getHours = `0${Math.floor(time / 3600)}`.slice(-2);

		return `${getHours}:${getMinutes}:${getSeconds}`;
	};

	const handleSendToOpenAI = async () => {
		let dataLoader = { note_id: noteId, is_note: true };
		localStorage.setItem('note_load', JSON.stringify(dataLoader));

		try {
			let objData = {
				action: 'after_transcription',
			};

			setActivePage('Home');
			navigate('/dashboard');

			const res = await handleUserUpdateNote(noteId, objData);

			if (res && noteId) {
				dataLoader = { note_id: noteId, is_note: false };
				localStorage.setItem('note_load', JSON.stringify(dataLoader));
				// localStorage.removeItem('note_load');
			}
			//  else {
			// 	localStorage.removeItem('note_load');
			// }
		} catch (error) {
			console.error('Error sending data to server:', error);
		}
	};

	return (
		<>
			{/* <ConfirmationModal
				// isVisible={isModalVisible}
				onClose={handleCloseModal}
				onConfirm={handleConfirmAction}
				isDisableButton={isDisableButton}
				content='Are you sure you want to proceed with this action?'
			/> */}

			<div className='absolute lg:left-[25%] top-[70px] p-6 md:px-12 md:py-8 w-full lg:w-[75%]'>
				<div className='flex flex-col'>
					{isLoading ? (
						<SkeletonTheme color='#202020' highlightColor='#aaa'>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Skeleton height={25} width={150} count={1} />
							</div>
						</SkeletonTheme>
					) : (
						<span className='flex items-center'>
							<p className='font-BricolageGrotesque text-2xl font-semibold text-[#272D37]'>{patientInfo?.name}</p>
							<span className='py-[2px] px-2 rounded-[5px] bg-[#ECFDFD] text-[#00AAAA] font-Inter text-sm font-medium ml-3'>
								{patientInfo?.pronoun}
							</span>
						</span>
					)}
					{/* {isActive ? 'is active' : 'Not active'} */}

					{isProcessing ? (
						<div className='flex space-x-2 mt-3 w-[150px] items-center justify-center py-[1px] px-[10px] rounded-[20px] bg-[#ECFDFD]'>
							<button className='w-2 h-2 rounded-full bg-[#00AAAA]'></button>
							<p className='text-sm font-Inter text-[#00AAAA]'>Transcribed Note</p>
						</div>
					) : (
						<div className='flex items-center mt-3'>
							<div
								className={`flex space-x-2 w-[200px] items-center justify-center py-[1px] px-[10px] rounded-[20px] ${
									!isActive ? 'bg-[#FF4E3E1A]' : 'bg-[#ECFDFD]'
								}`}
							>
								<button className={`w-2 h-2 rounded-full ${!isActive ? 'bg-[#FF4E3E]' : 'bg-[#00AAAA]'}`}></button>
								<p className={`text-sm font-Inter ${!isActive ? 'text-[#FF4E3E]' : 'text-[#00AAAA]'}`}>
									Live Transcription
								</p>
							</div>

							<p
								className={`font-BricolageGrotesque font-semibold ml-2 text-base md:text-xl ${
									!isActive ? 'text-gray-500' : 'text-red-500'
								}`}
							>
								{formatTime(time)}
							</p>

							<div className='flex flex-col md:flex-row items-center ml-auto'>
								<div
									className={`h-2 w-2 md:mr-1 rounded-full animate-ping ${isOnline ? 'bg-green-500' : 'bg-red-500'}`}
								></div>
								<p className='text-xs text-gray-700'>{isOnline ? 'Online' : 'Offline'}</p>
							</div>
						</div>
					)}
					<div
						contentEditable
						ref={contentEditableRef}
						suppressContentEditableWarning={true}
						className='w-full mt-3 h-[220px] lg:h-[160px] border border-[#EAEBF0] p-4 relative md:p-6 rounded-[10px] text-[#282D2D] font-Inter font-normal text-base md:text-base overflow-y-auto resize-y'
					>
						{transcriptFinalized.transcript || rawTranscript
							? (rawTranscript != null ? rawTranscript : ' ') + transcriptFinalized.transcript
							: 'Transcription'}
					</div>
					<div className='fixed left-0 bottom-6 lg:static w-full flex justify-center items-center mt-8 flex-col'>
						<div className='h-16 w-16'>
							{isPaused ? <img src={trans} className='w-full' alt='' loading='lazy' /> : <WaveAnimation />}
						</div>
						<p className='text-[#282D2D] text-center font-Inter mt-4 text-base font-normal'>
							Press stop to finish and get AI assisted
							<br /> Transcription
						</p>

						{isProcessing ? (
							<div className='w-[350px] bg-[#EA4335] py-4 rounded-[50px] mt-6 font-semibold font-BricolageGrotesque text-center text-base md:text-xl text-white'>
								Processing...
							</div>
						) : (
							<>
								<div className='space-x-4 flex items-center mt-8'>
									<button
										title='Play or Pause'
										disabled={!isDeepgramConnect}
										onClick={isActive ? (isPaused ? handleResume : handlePause) : handleStart}
										className={`px-6 md:px-8 py-4 rounded-[50px] text-white font-semibold flex justify-center items-center ${
											isPaused ? 'bg-[#00AAAA]' : 'bg-[#333]'
										}`}
									>
										<div
											className={`animate-spin rounded-full h-4 w-4 border-t-4 border-white-500 ${
												!isDeepgramConnect ? 'inline-block' : 'hidden'
											}`}
										></div>
										<img src={isPaused ? play : pause} className='w-5 md:w-auto' alt={isPaused ? 'Play' : 'Pause'} />
										&nbsp;
										{isPaused ? 'Start' : 'Pause'}
									</button>
									<button
										title='Stop'
										disabled={!isActive}
										onClick={handleStopRecording}
										className={`px-6 md:px-8 py-4 rounded-[50px] text-white font-semibold flex items-center space-x-3 ${
											!isActive ? 'bg-[#f1b0b0]' : 'bg-[#FF4E3E]'
										}`}
									>
										<img src={stop} alt='' />
										&nbsp; Stop
									</button>
									{/* <button
									disabled={!isActive}
									onClick={handleStopRecording}
									className='bg-[#EA4335] px-6 md:px-8 py-4 rounded-[50px] flex items-center space-x-3'
								>
									<img src={stop} alt='' />
								</button> */}
									<button className='hidden px-6 md:px-8 py-4 bg-[#F5F5F5] rounded-[50px] justify-center items-center'>
										<img src={trash} className='w-5 md:w-auto' alt='' />
									</button>
								</div>
								{!isDeepgramConnect && (
									<p className='mt-4 text-sm text-gray-500 text-center'>
										If the start button is not working, please refresh the page.
									</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Transcribing;

