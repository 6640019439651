import { useState, useEffect } from 'react';
import toTop from './assets/back-to-top.svg';

const BackToTop = () => {
	const [visible, setVisible] = useState(false);

	const toggleVisibility = () => {
		if (window.pageYOffset > 200) {
			setVisible(true);
		} else {
			setVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);
		return () => {
			window.removeEventListener('scroll', toggleVisibility);
		};
	}, []);

	return (
		<div className='fixed bottom-4 right-4 z-[100]'>
			{visible && (
				<button
					onClick={scrollToTop}
					className='bg-[#000] text-white p-2 rounded-full shadow-lg hover:bg-[#E1EAEA]'
					style={{ width: '30px' }}
				>
					<img src={toTop} alt='Recent' />
				</button>
			)}
		</div>
	);
};

export default BackToTop;
