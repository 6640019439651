import { useEffect, useState } from "react";
import close from "./assets/close cross.svg";
import upload from "./assets/upload.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MyAccount = ({ closeModal, profileDetails }) => {
  // console.log(profileDetails);

  const [name, setName] = useState(profileDetails.name || "");
  const [doraToken, setDoraToken] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const storedItem = localStorage.getItem("dora_token");
    if (storedItem) {
      setDoraToken(storedItem);
    }
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const url = new URL(`${API_BASE_URL}api/v1/profile`);

    const headers = {
      Authorization: `Bearer ${doraToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const body = {
      name,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success("Profile updated successfully!");
      } else {
        toast.error(result.message || "Failed to update profile.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("An error occurred while updating the profile.");
    }
  };

  const isEmailVerified = profileDetails.email_verified_at !== null;

  return (
    <>
      <ToastContainer
        style={{ zIndex: 999999 }} // Increase z-index here
      />

      <div className=" w-full md:w-[120%] h-full bg-[#1212128d] z-[999] fixed top-0 md:-left-[20%] px-6 flex justify-center items-center">
        <div className="w-full flex justify-center items-center">
          <div className="md:ml-[20%] bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] h-[480px] p-6 rounded-[15px] w-full md:w-[400px]">
            <div className="w-full h-full overflow-y-auto bg-white">
              <span className=" flex items-center justify-between">
                <p className=" text-[#272D37] font-semibold font-BricolageGrotesque text-xl">
                  My Account
                </p>
                <img
                  src={close}
                  className="cursor-pointer"
                  onClick={closeModal}
                  alt=""
                />
              </span>
              <p className=" font-Inter text-[#5F6D7E] font-normal text-base mt-2">
                Manage your account here.
              </p>
              <div className=" justify-center items-center flex flex-col mt-6">
                <div className=" relative flex justify-center items-center">
                  <span className=" w-20 h-20 rounded-full block bg-[#f5f5f5]"></span>
                  <button className=" w-7  h-7 bg-[#00AAAA] rounded-full absolute bottom-0 right-0 flex justify-center items-center">
                    <img src={upload} alt="" />
                  </button>
                </div>
              </div>
              {/* inputs  */}
              <div className=" mt-6 w-full">
                <label
                  htmlFor="fullName"
                  className="flex flex-col w-full font-BricolageGrotesque text-[#272D37] text-sm font-semibold"
                >
                  Full Name
                  <input
                    id="fullName"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full py-2.5 px-6 font-Inter capitalize text-[#919BA7] text-sm font-normal mt-[6px] border border-[#DAE0E6] rounded-[20px]"
                    placeholder="Enter First Name"
                  />
                </label>
                <label
                  htmlFor="email"
                  className="flex flex-col w-full mt-4 font-BricolageGrotesque text-[#272D37] text-sm font-semibold"
                >
                  Email
                  <input
                    id="email"
                    type="text"
                    value={profileDetails.email}
                    readOnly
                    className="w-full py-2.5 px-6 font-Inter text-[#919BA7] text-sm font-normal mt-[6px] border border-[#DAE0E6] rounded-[20px]"
                    placeholder="Enter Email"
                  />
                </label>

                <div className="justify-between w-full mt-6 flex items-center flex-row">
                  <button
                    onClick={handleUpdate}
                    className="py-3 rounded-[30px] shadow-sm shadow-[#1018280A] text-center font-semibold font-BricolageGrotesque text-base text-white w-full bg-[#00AAAA]"
                  >
                    Update Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
