import Navbar from "../../components/navbar";
import prieHero from "./assets/pricehero.webp";
import pattern from "./assets/Background pattern decorative.png";
import Faq from "../../components/faq";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";

const Policy = () => {
  const blog = [
    {
      name: "Dorascribe | AI Medical Scribe That Delivers - Real Time",
      link: "Dorascribe|AI-Medical-Scribe-That-Delivers-Real-Time",
      date: "May 1, 2024",
    },
  ];
  return (
    <>
      <Navbar />
      <div className=" px-4 md:px-8 w-full mt-28">
        <div
          style={{
            backgroundImage: `url(${prieHero})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="  h-[329px] w-full flex p-6 flex-col items-center justify-center relative rounded-[20px]"
        >
          <img src={pattern} className=" absolute left-0 -top-[200px]" alt="" />
          <p className=" font-BricolageGrotesque capitalize font-semibold text-4xl md:text-[75px] md:leading-[97px] text-center text-white">
            Terms and Privacy Policy
          </p>
          <p className=" mt-3 font-Inter text-[#f5f5f58b] text-base md:text-xl font-normal text-center lg:w-[800px]">
            Your use of our AI medical transcription platform is governed by our
            Terms and Privacy Policy, which include important information about
            your rights and responsibilities.
          </p>
        </div>
        <div className="py-8 md:py-16 px-4 md:px-14 w-full flex justify-center items-center flex-col">
          <div className="">
            <p className=" font-bold font-BricolageGrotesque text-3xl text-[#272D37] text-center">
              Introduction
            </p>
            <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
              Welcome to Dorascribe Inc. (“Company”, “we”, “us”). These Terms
              and Conditions (“Terms”) are a binding contract between you
              (“Client”) and the Company. By using our website, products,
              services, and applications, including our AI transcription
              platform (the “App”), you agree to be bound by these Terms,
              including our Privacy Policy detailed herein.
            </p>
          </div>
          <div className=" mt-6">
            <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
              Modifications and Updates to Terms
            </p>
            <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
              We reserve the right to change these Terms at any time. We will
              notify you of any changes by placing a notice on our website or by
              other means. Continued use of the Services after such changes
              constitutes acceptance of the new Terms.
            </p>
          </div>
          <div className=" mt-6">
            <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
              Service Registration Procedures
            </p>
            <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
              You agree to provide accurate, current, and complete information
              during the registration process and to update this information
              promptly if it changes. You are responsible for all activities
              that occur under your account and must notify us immediately of
              any unauthorized use.
            </p>
          </div>
          <div className=" mt-6">
            <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
              Acceptable Use
            </p>
            <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
              You agree not to use the Services in any way that violates any
              applicable laws or regulations. Prohibited uses include, but are
              not limited to, reverse engineering the App, accessing the App in
              a way intended to avoid fees, and using the App for illegal or
              unauthorized purposes.
            </p>
          </div>
          <div className=" mt-6">
            <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
              Confidentiality
            </p>
            <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
              You agree to use Confidential Information only in connection with
              your use of the Services and to protect it from unauthorized
              disclosure. Confidential Information includes any non-public
              information disclosed by the Company that is designated as
              confidential or would reasonably be understood to be confidential.
            </p>
          </div>
          <div className=" mt-6">
            <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
              Intellectual Property
            </p>
            <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
              The Company retains all rights to the intellectual property of the
              Services. You are granted a limited, non-exclusive,
              non-transferable license to use the Services as provided under
              these Terms.
            </p>
          </div>
          <div className=" mt-6">
            <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
              Limitation of Liability
            </p>
            <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
              The Services are provided "as is" and "as available". We do not
              guarantee accuracy and are not liable for any damages arising from
              the use of the Services. Your use of the Services is at your own
              risk.
            </p>
          </div>
          <div className=" mt-6">
            <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
              Termination
            </p>
            <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
              We may terminate or suspend your account and access to the
              Services at our sole discretion, without prior notice, for conduct
              that we believe violates these Terms or is harmful to other users
              of the Services, us, or third parties.
            </p>
          </div>
          <div className=" mt-6">
            <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
              Governing Law
            </p>
            <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
              These Terms are governed by and construed in accordance with the
              laws of the jurisdiction in which the Company is based, without
              regard to its conflict of law principles.
            </p>
          </div>

          <div className=" mt-6">
            <p className=" font-bold font-BricolageGrotesque text-3xl text-[#272D37] text-center">
              Privacy Policy
            </p>
            <div className=" mt-6">
              <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
                Introduction
              </p>
              <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
                Your privacy is critically important to us, and this section
                explains how we collect, use, disclose, and safeguard your
                information when you use our services.
              </p>
            </div>
            <div className=" mt-6">
              <p className=" font-bold font-BricolageGrotesque text-2xl mb-4 text-[#272D37] text-center">
                Information We Collect
              </p>
              <ol className="list-decimal list-outside space-y-4 custom-list font-Inter text-[#5F6D7E] text-base">
                <li>
                  <strong className=" text-black">Personal Information:</strong>{" "}
                  When you register with Docscribe, we collect personal
                  information such as your name, email address, phone number,
                  and payment details.
                </li>
                <li>
                  <strong className=" text-black">Usage Data:</strong> We
                  collect information about your interactions with our services,
                  including the audio and transcription data you upload, the
                  features you use, and the time and date of your usage.
                </li>
                <li>
                  <strong className=" text-black">Device Information:</strong>{" "}
                  Information about the device you use to access our services,
                  such as IP address, operating system, and browser type.
                </li>
              </ol>
            </div>
            <div className=" mt-6">
              <p className=" font-bold font-BricolageGrotesque text-2xl mb-4 text-[#272D37] text-center">
                How We Use Your Information
              </p>
              <ol className="list-decimal list-outside space-y-4 custom-list font-Inter text-[#5F6D7E] text-base">
                <li>
                  <strong className=" text-black">
                    To Provide and Improve Services:
                  </strong>{" "}
                  We use the information to deliver and enhance our services,
                  ensuring they meet your needs and preferences.
                </li>
                <li>
                  <strong className=" text-black">Communication:</strong> To
                  contact you with updates, promotional materials, and other
                  information related to our services.
                </li>
                <li>
                  <strong className=" text-black">Security:</strong>
                  To protect our services and users from fraud, abuse, and
                  unauthorized access.
                </li>
              </ol>
            </div>
            <div className=" mt-6">
              <p className=" font-bold font-BricolageGrotesque text-2xl mb-4 text-[#272D37] text-center">
                Information Sharing
              </p>
              <ol className="list-decimal list-outside space-y-4 custom-list font-Inter text-[#5F6D7E] text-base">
                <li>
                  <strong className=" text-black">Service Providers:</strong> W
                  We may share your information with third-party service
                  providers who perform services on our behalf, such as payment
                  processing and data analysis.
                </li>
                <li>
                  <strong className=" text-black">Legal Requirements:</strong>{" "}
                  We may disclose your information if required to do so by law
                  or in response to valid requests by public authorities.
                </li>
                <li>
                  <strong className=" text-black">Business Transfers:</strong>
                  In the event of a merger, acquisition, or sale of all or a
                  portion of our assets, your information may be transferred as
                  part of that transaction.
                </li>
              </ol>
            </div>
            <div className=" mt-6">
              <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
                Data Security
              </p>
              <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
                We implement appropriate technical and organizational measures
                to protect your information against unauthorized access,
                alteration, disclosure, or destruction.
              </p>
            </div>
            <div className=" mt-6">
              <p className=" font-bold font-BricolageGrotesque text-2xl mb-4 text-[#272D37] text-center">
                Your Rights
              </p>
              <ol className="list-decimal list-outside space-y-4 custom-list font-Inter text-[#5F6D7E] text-base">
                <li>
                  <strong className=" text-black">
                    Access and Correction:
                  </strong>{" "}
                  You have the right to access and correct the personal
                  information we hold about you.
                </li>
                <li>
                  <strong className=" text-black">Data Portability:</strong> You
                  have the right to request a copy of your personal data in a
                  structured, commonly used, and machine-readable format.
                </li>
                <li>
                  <strong className=" text-black">Deletion:</strong>
                  You have the right to request that we delete your personal
                  information, subject to certain exceptions.
                </li>
              </ol>
            </div>

            <div className=" mt-6">
              <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
                Changes to These Terms and Privacy Policy
              </p>
              <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
                We may update our Terms and Privacy Policy from time to time. We
                will notify you of any changes by posting the new Terms and
                Privacy Policy on this page.
              </p>
            </div>
            <div className=" mt-6">
              <p className=" font-bold font-BricolageGrotesque text-2xl text-[#272D37] text-center">
                Contact Us
              </p>
              <p className=" mt-4 text-center font-Inter font-normal text-base text-[#5F6D7E]">
                If you have any questions or concerns about these Terms and
                Conditions, including our Privacy Policy, please contact us at
                help@dorascribe.com.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Policy;
