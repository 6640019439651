import { useState } from "react";
import { handleUserUpdateNote } from "../../../controllers/NoteController";
import close from "./assets/close cross.svg";

const PronounButton = ({ value, selectedPronoun, setPronoun }) => {
  const isSelected = value === selectedPronoun;
  return (
    <button
      className={`w-1/3 py-2 rounded-[25px] text-center font-Inter font-medium text-base ${
        isSelected ? "bg-[#00808066] text-white" : "text-[#282D2D]"
      }`}
      onClick={() => setPronoun(value)}
    >
      {value}
    </button>
  );
};

const EditNote = ({ noteId, toggleEditNote, onNoteEdited }) => {
  const [patient_pronoun, setPatientPronoun] = useState("He"); // Default pronoun selection
  const [patient_name, setPatientName] = useState("");

  const handleEditNote = async () => {
    const userData = {
      patient_name,
      patient_pronoun,
    };

    const response = await handleUserUpdateNote(noteId, userData);

    if (response.data) {
      toggleEditNote();
      const patientData = {
        name: response.data.data.patient_name,
        pronoun: response.data.data.patient_pronoun,
      };

      localStorage.setItem("patient_info", JSON.stringify(patientData)); // For sidebar update

      onNoteEdited(response.data.data); // Emit the response to the parent
    }
  };

  return (
    <>
      <div
        className=" w-full md:w-[120%] h-full bg-[#1212128d] z-[99999] fixed
      top-0 md:-left-[20%] px-6 flex justify-center items-center"
      >
        <div className="w-full flex justify-center items-center">
          <div className=" md:ml-[20%] bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] h-[350px] p-6 rounded-[15px] w-full md:w-[400px]">
            <div className="w-full h-full overflow-y-auto bg-white">
              <span className=" flex items-center justify-between">
                <p className=" font-BricolageGrotesque font-semibold text-xl text-[#272D37]">
                  Edit Note
                </p>
                <img
                  onClick={toggleEditNote}
                  src={close}
                  className="cursor-pointer"
                  alt=""
                />
              </span>
              <div className=" mt-6">
                <label
                  className=" flex w-full flex-col text-[#272D37] font-semibold font-BricolageGrotesque text-sm"
                  htmlFor=""
                >
                  Name
                  <input
                    className=" mt-[6px] border border-[#DAE0E6] font-Inter text-sm font-normal rounded-[20px] p-2.5"
                    type="text"
                    placeholder="Enter Patient Name"
                    value={patient_name}
                    onChange={(e) => setPatientName(e.target.value)}
                  />
                </label>
                <label
                  htmlFor=""
                  className=" mt-6 flex flex-col w-full text-[#272D37] font-semibold font-BricolageGrotesque text-sm"
                >
                  Pronoun
                  <span className=" w-full flex items-center p-1 mt-[6px] border border-[#DAE0E6] rounded-[60px]">
                    {["He", "She", "They"].map((value) => (
                      <PronounButton
                        key={value}
                        value={value}
                        selectedPronoun={patient_pronoun}
                        setPronoun={setPatientPronoun}
                      />
                    ))}
                  </span>
                </label>
                <label
                  htmlFor=""
                  className="hidden mt-6 flex-col w-full text-[#272D37] font-semibold font-BricolageGrotesque text-sm"
                >
                  Type of Note Generated
                  <span className=" w-full flex items-center py-3 px-4 mt-[6px] border border-[#DAE0E6] rounded-[20px]">
                    <select
                      name=""
                      id=""
                      className=" bg-transparent font-Inter font-medium text-sm text-[#272D37] w-full"
                    >
                      <option value="SOAP Note">SOAP Note</option>
                      <option value="SOAP Note">Custom</option>
                      <option value="SOAP Note">Obestries</option>
                      <option value="SOAP Note">Gynatelololy</option>
                      <option value="SOAP Note">Psychiatry</option>
                      <option value="SOAP Note">Comprehensive</option>
                    </select>
                  </span>
                </label>
                <button
                  onClick={handleEditNote}
                  className=" mt-6 w-full space-x-3 flex items-center bg-[#00AAAA] rounded-[30px]  justify-center  py-3"
                >
                  <p className=" text-white font-BricolageGrotesque font-semibold text-base">
                    Save
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNote;
