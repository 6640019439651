import { useContext, useEffect, useState } from "react";
import logo from "./assets/Logo.svg";
import shorts from "./assets/shorts.svg";
import { Link } from "react-router-dom";
import CardForm from "./components/cardForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PlanPriceIdContext } from "./contexts/ActivePageContext";

const CardDetailsTrial = () => {
  const [stripepromise, setStripePromise] = useState(null);
  const { planPriceId, setPlanPriceId } = useContext(PlanPriceIdContext);

  useEffect(() => {
    const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    setStripePromise(stripe);
  }, []);

  const [doraToken, setDoraToken] = useState(null);

  useEffect(() => {
    // Get the item from localStorage
    const storedItem = localStorage.getItem("dora_token");
    // Set the token if it exists
    if (storedItem) {
      setDoraToken(storedItem);
    }
  }, []);

  return (
    <div className="w-full h-[100vh] flex justify-center items-center bg-[#f1f1f1] text-[#000]">
      <div className="p-6 w-full max-w-[1280px] lg:max-h-[630px] bg-[#fff] h-full flex rounded-[15px] flex-row justify-center lg:justify-between">
        <div className="w-full lg:w-[49%] relative h-full flex flex-col items-center justify-center">
          <Link to="/" className="cursor-pointer">
            <img src={logo} alt="Dorascribe" />
          </Link>
          <p className="mt-6 font-BricolageGrotesque font-semibold text-2xl text-black">
            Start Your 14-Days Free Trial
          </p>
          <p className="mt-1 text-[#282D2D] font-Inter font-normal text-center text-lg">
            Enter your Card details to proceed with the free trial
          </p>

          <>
            <Elements stripe={stripepromise}>
              <CardForm doraToken={doraToken} planPriceId={planPriceId} />
            </Elements>
          </>
        </div>
        <div
          style={{
            backgroundImage: `url(${shorts})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="w-full lg:w-[49%] bg-[#f1f1f1] rounded-[20px] relative hidden lg:flex flex-col justify-center"
        ></div>
      </div>
    </div>
  );
};

export default CardDetailsTrial;
